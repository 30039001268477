import React, { useState, useEffect } from 'react';
import {
    useLocation
} from "react-router-dom";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Button, Image } from 'antd';
import TabelaViaturaCliente from '../../../components/pt/tabelaViaturaCliente';
import LinkPartilhaExpira from '../../../components/pt/link-partilha-expira';
import FuncContactDetails from '../../../components/pt/funcionario/func-contact-details';
import HeaderSimulador from '../../../components/pt/header/header-simulador';
import ES_CalculoChaveMao from '../../../components/es/es_calculo-chave-mao';
import ES_ResultadodaSimulacao from '../../../components/es/es_resultado-da-simulacao';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { FaWhatsapp } from 'react-icons/fa6';
import { HiOutlineMail } from 'react-icons/hi';
import PartilhaRedesProposta from '../../../components/pt/partilha/partilha-redes-proposta';
import ES_PartilhaRedesProposta from '../../../components/es/es_partilha-redes-proposta';
import axios from 'axios';


dayjs.extend(customParseFormat);


export default function ES_Calculo({
    nomeGeradoDaProposta,
    dataCarros,
    temParametros,
    formatarNumero,
    isDarkTheme,
    currentLanguage,
    setIsDarkTheme
}) {

    const { t } = useTranslation();

    const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
    })


    //outros
    const [alertasNoPedido, setAlertasNoPedido] = useState(false);


    //para ISV
    const [tipoDeVeiculoParaInsencoes, setTipoDeVeiculoParaInsencoes] = useState('');
    const [novoOuUsado, setNovoOuUsado] = useState('');
    const [dataDeRegisto, setDataDeRegisto] = useState('');
    const [cilindrada, setCilindrada] = useState('');
    const [tipoDeCombustivel, setTipoDeCombustivel] = useState('');
    const [emissoesGases, setEmissoesGases] = useState('');



    //para ISV e IUC -ESPANHOL
    const [idModelo, setIdModelo] = useState('');
    const [listaDeModelos, setListaDeModelos] = useState('');

    const [idMarca, setIdMarca] = useState('');
    const [listaDeMarcas, setListaDeMarcas] = useState('');

    const [valorViaturaNova, setValorViaturaNova] = useState('');
    const [tipoCarro, setTipoCarro] = useState('');

    const [idCidade, setIdCidade] = useState('');
    const [listaDeCidades, setListaDeCidades] = useState('');

    const [idMunicipio, setIdMunicipio] = useState('');
    const [listaDeMunicipios, setListaDeMunicipios] = useState('');



    const [iucCavalosFiscais, setIucCavalosFiscais] = useState('');
    const [iucCidade, setIucCidade] = useState('');
    const [iucMunicipio, setIucMunicipio] = useState('');
    const [iucPercentagemDescontoEletrico, setIucPercentagemDescontoEletrico] = useState('');



    const [isvComunidad, setIsvComunidad] = useState('');
    const [isvValoracionInicial, setIsvValoracionInicial] = useState('');
    const [isvPorcentajeaAplicar, setIsvPorcentajeaAplicar] = useState('');
    const [isvMinoracion, setIsvMinoracion] = useState('');
    const [isvBaseImponible, setIsvBaseImponible] = useState('');
    const [isvReduccionBaseImponible, setIsvReduccionBaseImponible] = useState('');
    const [isvBaseImponibleFinal, setIsvBaseImponibleFinal] = useState('');
    const [isvCuotaTributaria, setIsvCuotaTributaria] = useState('');
    const [isvTotalaPagar, setIsvTotalaPagar] = useState('');


    //para calculos
    const [taxaDeServico, setTaxaDeServico] = useState('2800');
    const [precoFinalCarro, setPrecoFinalCarro] = useState('');
    const [valorFinalISV, setValorFinalISV] = useState('0');
    const [dataGeralISV, setDataGeralISV] = useState('');

    const [valorFinalIUC, setValorFinalIUC] = useState('0');
    const [dataGeralIUC, setDataGeralIUC] = useState('');


    const [valorChaveNaMaoEmpresa, setValorChaveNaMaoEmpresa] = useState('');
    const [valorChaveNaMaoParticular, setValorChaveNaMaoParticular] = useState('');


    //Dados - Validar Dados - Guardar Dados
    const [precoFinalDoCarroSemIva, setPrecoFinalDoCarroSemIva] = useState('');
    const [precoFinalDoCarroIvaDedutival, setPrecoFinalDoCarroIvaDedutival] = useState(false);
    //const [selecionarIsencao, setSelecionarIsencao] = useState(true);
    const [hibridoIsChecked, setHibridoIsChecked] = useState(false);
    const [co2IsChecked, setCo2IsChecked] = useState(false);
    //  const [wltpIsChecked, setWltpIsChecked] = useState(false);
    const [outrosServicos, setOutrosServicos] = useState('');
    const [validarAnoDeRegisto, setValidarAnoDeRegisto] = useState(false);
    const [validarViaturaEletrico, setValidarViaturaEletrico] = useState(false);
    // VALIDACAO HIBRIDO PLUGIN
    // const [hibridoValidationIsChecked, setHibridoValidationIsChecked] = useState(false);
    // PARA SABER SE É TAB PARTICULAR OU EMPRESA (PARTICULAR COMEÇA COM TRUE)
    const [isParticular, setIsParticular] = useState(true);

    const [erroInicial, setErroInicial] = useState('Ocorreu algum erro, tente novamente!');
    const [infoInicialPartilhaProposta, setInfoInicialPartilhaProposta] = useState('');

    //Resultado da Simulação
    const [activeTabKeyResultadoSimulacao, setActiveTabKeyResultadoSimulacao] = useState("1");
    const [tabResultadoSimulacao, setTabResultadoSimulacao] = useState("0");


    //partilhar com cliente
    const [nomeDaProposta, setNomeDaProposta] = useState('');
    const [allDataLinkPartilhado, setAllDataLinkPartilhado] = useState('');


    const [nomePerfilDataFuncionario, setNomePerfilDataFuncionario] = useState('');
    const [fotoPerfilDataFuncionario, setFotoPerfilDataFuncionario] = useState('');
    const [whatsappDataFuncionario, setWhatsappDataFuncionario] = useState('');
    const [emailDataInternoFuncionario, setEmailDataInternoFuncionario] = useState('');


    const [IgnorarAvisos, setIgnorarAvisos] = useState(false);



    const location = useLocation();


    const [dataIdMarca, setDataIdMarca] = useState([]);
    const [dataIdModelo, setDataIdModelo] = useState([]);
    const [dataIdCidade, setDataIdCidade] = useState([]);
    const [dataIdMunicipio, setDataIdMunicipio] = useState([]);



    //valida se tem parametros na url
    // //valida se tem parametros na url
    useEffect(() => {

        if (dataCarros) {

            const dataLinkPartilhado = dataCarros.data.data


            const idMarca = (dataLinkPartilhado.id_marca);
            const idModelo = (dataLinkPartilhado.id_modelo);
            const idCidade = (dataLinkPartilhado.id_cidade);
            const idMunicipio = (dataLinkPartilhado.id_municipio);


            let pedidoMarca = `${process.env.REACT_APP_GET_MARCA_ES}?id_marca=${idMarca}`;

            axios.get(pedidoMarca)
                .then((response) => {
                    if (response.data.sucesso) {
                        // console.log(response.data)
                        setDataIdMarca(response.data.mensagem[0].nome_marca)
                    } else {

                    }
                })
                .catch((error) => {

                });



            let pedidoModelo = `${process.env.REACT_APP_GET_MODELO_ES}?id_modelo=${idModelo}`;

            axios.get(pedidoModelo)
                .then((response) => {
                    if (response.data.sucesso) {
                        // console.log(response.data)
                        setDataIdModelo(response.data.mensagem[0].nome_modelo)
                    } else {

                    }
                })
                .catch((error) => {

                });




            let pedidoCidade = `${process.env.REACT_APP_GET_CIDADE_ES}?id_cidade=${idCidade}`;

            axios.get(pedidoCidade)
                .then((response) => {
                    if (response.data.sucesso) {
                        // console.log(response.data)
                        setDataIdCidade(response.data.mensagem[0].nome_cidade)
                    } else {

                    }
                })
                .catch((error) => {

                });




            let pedidoMunicipio = `${process.env.REACT_APP_GET_MUNICIPIO_ES}?id_municipio=${idMunicipio}`;

            axios.get(pedidoMunicipio)
                .then((response) => {
                    if (response.data.sucesso) {
                        // console.log(response.data.mensagem.nome_municipio)
                        setDataIdMunicipio(response.data.mensagem[0].nome_municipio)
                    } else {

                    }
                })
                .catch((error) => {

                });







            const whatsappFuncionario = dataCarros?.data?.user.whatsapp
            const emailInternoFuncionario = dataCarros?.data?.user.email_interno
            const fotoPerfilInternoFuncionario = dataCarros?.data?.user.foto_perfil
            const nomePerfilInternoFuncionario = dataCarros?.data?.user.nome_user
            setNomePerfilDataFuncionario(nomePerfilInternoFuncionario)
            setFotoPerfilDataFuncionario(fotoPerfilInternoFuncionario)
            setWhatsappDataFuncionario(whatsappFuncionario)
            setEmailDataInternoFuncionario(emailInternoFuncionario)



            // console.log('dataLinkPartilhado-dataLinkPartilhado', dataLinkPartilhado)
            setAllDataLinkPartilhado(dataLinkPartilhado)
            processosRepetidosNosPedidos(dataLinkPartilhado);
            setValorFinalISV(dataLinkPartilhado.isv)
            setValorFinalIUC(dataLinkPartilhado.iuc)


            setIdMarca(dataLinkPartilhado.id_marca);
            setIdModelo(dataLinkPartilhado.id_modelo);
            setIdCidade(dataLinkPartilhado.id_cidade);
            setIdMunicipio(dataLinkPartilhado.id_municipio);


            setValorViaturaNova(dataLinkPartilhado.valor_viatura_nova)

            setIucCavalosFiscais(dataLinkPartilhado.iuc_cavalos_fiscais);
            setIucCidade(dataLinkPartilhado.iuc_cidade);
            setIucMunicipio(dataLinkPartilhado.iuc_municipio);
            setIucPercentagemDescontoEletrico(dataLinkPartilhado.iuc_percentagem_desconto_eletrico);


            setIsvComunidad(dataLinkPartilhado.isv_comunidad);
            setIsvValoracionInicial(dataLinkPartilhado.isv_valoracion_inicial);
            setIsvPorcentajeaAplicar(dataLinkPartilhado.isv_porcentaje_aplicar);
            setIsvMinoracion(dataLinkPartilhado.isv_minoracion);
            setIsvBaseImponible(dataLinkPartilhado.isv_base_imponible);
            setIsvReduccionBaseImponible(dataLinkPartilhado.isv_reduccion_base_imponible);
            setIsvBaseImponibleFinal(dataLinkPartilhado.isv_base_imponible_final);
            setIsvCuotaTributaria(dataLinkPartilhado.isv_cuota_tributaria);
            setIsvTotalaPagar(dataLinkPartilhado.isv_total_pagar);

            setTaxaDeServico(dataLinkPartilhado.taxadeservico)
            setInfoInicialPartilhaProposta(dataCarros.data.data_expira)
            setIgnorarAvisos(true);
            setTabResultadoSimulacao(dataCarros.data.data.empresa)



            if (dataLinkPartilhado.tipo_de_combustivel === "Eletrico") {
                setValidarViaturaEletrico(true)
            } else {
                setValidarViaturaEletrico(false)
            }


        }

    }, [dataCarros]);




    // CODIGO QUE SEJA EXATAMENTE O MESMO PARA OS DOIS PEDIDOS (AUTOSCOUT24 E MOBILE.DE ou + no futuro)
    function processosRepetidosNosPedidos(dataFinalDadosObjeto) {


        if (dataFinalDadosObjeto.novo_ou_usado === "1") {
            setNovoOuUsado('Novo')
        } else {
            setNovoOuUsado('Usado')
        }

        // IVA NA ORIGEM
        // setIvaNaOrigem(dataFinalDadosObjeto.tem_iva)
        // setValorIvaNaOrigem(dataFinalDadosObjeto.perc_iva)


        // Tipo de Combustivel
        setTipoDeCombustivel(dataFinalDadosObjeto.tipo_combustivel_traduzido)

        // CILINDRADA
        setCilindrada(dataFinalDadosObjeto.cilindrada)

        // EMISSOES
        setEmissoesGases(dataFinalDadosObjeto.emissoes_numerico)

        // Data primeiro Registo
        setDataDeRegisto(dataFinalDadosObjeto.data_registo ? dataFinalDadosObjeto.data_registo : '')



        // TIPO DE TESTES:
        //  setTipoDeTestes('NEDC');
        setValidarAnoDeRegisto(false)
        // setAnoDoRegisto(false)

        // PARA CIMA DE 10/2018 - WLTP POR DEFEITO
        let data_registo = dataFinalDadosObjeto.data_registo;

        // PRECO DA VIATURA (COM IVA)
        const precoViatura = dataFinalDadosObjeto.preco_final_carro ? dataFinalDadosObjeto.preco_final_carro : "";
        setPrecoFinalCarro(precoViatura)


        setPrecoFinalDoCarroIvaDedutival(false)
        let precoViaturaSemIva = precoViatura;

        if (dataFinalDadosObjeto.preco_final_carro_sem_iva) { // SE EXISTIR SEM IVA
            setPrecoFinalDoCarroIvaDedutival(true)
            precoViaturaSemIva = dataFinalDadosObjeto.preco_final_carro_sem_iva;
        }
        setPrecoFinalDoCarroSemIva(precoViaturaSemIva)

        /*  //validar a quantidade de KM
          let quantidadeKM = dataFinalDadosObjeto.quantidade_de_km ? dataFinalDadosObjeto.quantidade_de_km : "";
  
          if (quantidadeKM) {
              quantidadeKM = parseFloat(quantidadeKM.toString().replace(",", ""));
          }*/

        let tipo_combustivel_raw = dataFinalDadosObjeto.tipo_de_combustivel ? dataFinalDadosObjeto.tipo_de_combustivel : "";
        let tipo_combustivel = dataFinalDadosObjeto.tipo_combustivel_traduzido ? dataFinalDadosObjeto.tipo_combustivel_traduzido : "";

        if (data_registo) {

            // SEPARAR AS DATA EM DUAS VARIAVEIS
            let [data_registo_month, data_registo_year] = data_registo.split('/');
            // CRIAR COMO DATA (ANO, MES)
            data_registo = new Date(data_registo_year, data_registo_month - 1);
            // CRIAR A DATA DO WLTP
            let data_wltp = new Date(2018, 10 - 1);

            // COMPARAR DATAS
            if (data_registo > data_wltp) {
                //setTipoDeTestes('WLTP');
                //   setWltpIsChecked(true);
                setValidarAnoDeRegisto(true)
            }


            //Validar eletrico
            if (tipo_combustivel_raw === "Electric") {
                setValidarViaturaEletrico(true)
                setValorFinalISV('0€')
                setAlertasNoPedido(false)
                setValidarAnoDeRegisto(false)
                setCo2IsChecked(true)
            } else {
                setValidarViaturaEletrico(false)
            }


            //validar hibrido
            if (tipo_combustivel_raw === "Hybrid (petrol/electric)") {
                setHibridoIsChecked(true)
                setValorFinalISV('0€')
                setCo2IsChecked(false)
                setTipoDeCombustivel('Gasolina')

            } else if (tipo_combustivel_raw === "Hybrid (diesel/electric)") {
                setHibridoIsChecked(true)
                setValorFinalISV('0€')
                setCo2IsChecked(false)
                setTipoDeCombustivel('Gasoleo')
                tipo_combustivel = 'Gasoleo'
            } else {
                setHibridoIsChecked(false)
            }


        }
    }


    useEffect(() => {


        let chaveNaMaoValue = ""
        let precoFinalCarroCal = ""

        let taxaDeServicoCal = parseFloat(taxaDeServico);
        let valorFinalISVCal = parseFloat(valorFinalISV);

        if (isNaN(taxaDeServicoCal)) {
            taxaDeServicoCal = 0;
        }

        // Particular
        precoFinalCarroCal = parseFloat(precoFinalCarro);
        if (!isNaN(precoFinalCarroCal) && !isNaN(valorFinalISVCal)) {
            chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal + valorFinalISVCal;
            setValorChaveNaMaoParticular(chaveNaMaoValue);
        } else {
            chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal;
            setValorChaveNaMaoParticular(chaveNaMaoValue);
        }

        // EMPRESA
        precoFinalCarroCal = parseFloat(precoFinalDoCarroSemIva);
        if (!isNaN(precoFinalCarroCal) && !isNaN(valorFinalISVCal)) {
            chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal + valorFinalISVCal;
            setValorChaveNaMaoEmpresa(chaveNaMaoValue);
        } else {
            chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal;
            setValorChaveNaMaoEmpresa(chaveNaMaoValue);
        }


    }, [precoFinalCarro, taxaDeServico, valorFinalISV]);


    const [tituloDoMobileDaViatura, setTituloDoMobileDaViatura] = useState('');


    useEffect(() => {

        setTituloDoMobileDaViatura(allDataLinkPartilhado?.titulo)

    }, [allDataLinkPartilhado]);


    return (

        <div className='w-full flex flex-col justify-center items-center gap-4 p-2 sm:p-4'>
            <div className='w-full h-full flex flex-col gap-2 md:gap-4'>
                <div className='w-full'>
                    <HeaderSimulador
                        isMobile={isMobile}
                        nomePerfilDataFuncionario={nomePerfilDataFuncionario}
                        fotoPerfilDataFuncionario={fotoPerfilDataFuncionario}
                        whatsappDataFuncionario={whatsappDataFuncionario}
                        emailDataInternoFuncionario={emailDataInternoFuncionario}
                        isDarkTheme={isDarkTheme}
                        setIsDarkTheme={setIsDarkTheme}
                    />
                </div>
                <div className='w-full text-white flex-1 overflow-y-auto' style={{ paddingBottom: isMobile ? '90px' : '0' }}>
                    <div className='grid gap-4'>
                        <div className='flex flex-col sm:flex-row gap-4 w-full h-full'>
                            <div className='w-full sm:w-3/5 grid gap-4'>
                                <div className='card'>
                                    <TabelaViaturaCliente
                                        currentLanguage={currentLanguage}
                                        isMobile={isMobile}
                                        nomeGeradoDaProposta={nomeGeradoDaProposta}
                                        allDataLinkPartilhado={allDataLinkPartilhado}
                                        formatarNumero={formatarNumero}
                                        dataCarros={dataCarros}
                                        isParticular={isParticular}
                                    />
                                </div>

                            </div>
                            <div className="w-full sm:w-2/5 flex flex-col gap-4">
                                <ES_CalculoChaveMao
                                    dataIdMarca={dataIdMarca}
                                    dataIdModelo={dataIdModelo}
                                    dataIdCidade={dataIdCidade}
                                    dataIdMunicipio={dataIdMunicipio}
                                    valorViaturaNova={valorViaturaNova}
                                    setValorViaturaNova={setValorViaturaNova}
                                    setListaDeModelos={setListaDeModelos}
                                    setIdModelo={setIdModelo}
                                    idModelo={idModelo}
                                    listaDeModelos={listaDeModelos}
                                    idMarca={idMarca}
                                    setIdMarca={setIdMarca}
                                    listaDeMarcas={listaDeMarcas}
                                    setIdMunicipio={setIdMunicipio}
                                    idMunicipio={idMunicipio}
                                    listaDeMunicipios={listaDeMunicipios}
                                    idCidade={idCidade}
                                    setIdCidade={setIdCidade}
                                    listaDeCidades={listaDeCidades}
                                    allDataLinkPartilhado={allDataLinkPartilhado}
                                    validarViaturaEletrico={validarViaturaEletrico}
                                    temParametros={temParametros}
                                    dataDeRegisto={dataDeRegisto}
                                    novoOuUsado={novoOuUsado}
                                    tipoDeCombustivel={tipoDeCombustivel}
                                    setTaxaDeServico={setTaxaDeServico}
                                    hibridoIsChecked={hibridoIsChecked}
                                    setCo2IsChecked={setCo2IsChecked}
                                    setNovoOuUsado={setNovoOuUsado}
                                    setOutrosServicos={setOutrosServicos}
                                    setAlertasNoPedido={setAlertasNoPedido}
                                    setCilindrada={setCilindrada}
                                    setDataDeRegisto={setDataDeRegisto}
                                    setEmissoesGases={setEmissoesGases}
                                    setTipoDeCombustivel={setTipoDeCombustivel}
                                    setTipoDeVeiculoParaInsencoes={setTipoDeVeiculoParaInsencoes}
                                    alertasNoPedido={alertasNoPedido}
                                    taxaDeServico={taxaDeServico}
                                    tipoDeVeiculoParaInsencoes={tipoDeVeiculoParaInsencoes}
                                    outrosServicos={outrosServicos}
                                    cilindrada={cilindrada}
                                    emissoesGases={emissoesGases}
                                    validarAnoDeRegisto={validarAnoDeRegisto}
                                    co2IsChecked={co2IsChecked}
                                    valorFinalISV={valorFinalISV}
                                    dataGeralISV={dataGeralISV}
                                    IgnorarAvisos={IgnorarAvisos}
                                />

                                <ES_ResultadodaSimulacao
                                    activeTabKeyResultadoSimulacao={activeTabKeyResultadoSimulacao}
                                    setActiveTabKeyResultadoSimulacao={setActiveTabKeyResultadoSimulacao}
                                    formatarNumero={formatarNumero}
                                    valorFinalIUC={valorFinalIUC}
                                    valorFinalISV={valorFinalISV}

                                    iucCavalosFiscais={iucCavalosFiscais}
                                    iucCidade={iucCidade}
                                    iucMunicipio={iucMunicipio}
                                    iucPercentagemDescontoEletrico={iucPercentagemDescontoEletrico}
                                    isvComunidad={isvComunidad}
                                    isvValoracionInicial={isvValoracionInicial}
                                    isvPorcentajeaAplicar={isvPorcentajeaAplicar}
                                    isvMinoracion={isvMinoracion}
                                    isvBaseImponible={isvBaseImponible}
                                    isvReduccionBaseImponible={isvReduccionBaseImponible}
                                    isvBaseImponibleFinal={isvBaseImponibleFinal}
                                    isvCuotaTributaria={isvCuotaTributaria}
                                    isvTotalaPagar={isvTotalaPagar}

                                    // dataGeralIUC={dataGeralIUC}
                                    taxaDeServico={taxaDeServico}
                                    precoFinalCarro={precoFinalCarro}
                                    valorChaveNaMaoParticular={valorChaveNaMaoParticular}
                                    valorChaveNaMaoEmpresa={valorChaveNaMaoEmpresa}
                                    // dataCarros={dataCarros}
                                    precoFinalDoCarroSemIva={precoFinalDoCarroSemIva}
                                    tabResultadoSimulacao={tabResultadoSimulacao}

                                />
                                <div className='card'>
                                    <ES_PartilhaRedesProposta tituloDoMobileDaViatura={tituloDoMobileDaViatura} nomeGeradoDaProposta={nomeGeradoDaProposta} />
                                </div>
                                <FuncContactDetails nomePerfilDataFuncionario={nomePerfilDataFuncionario} fotoPerfilDataFuncionario={fotoPerfilDataFuncionario} whatsappDataFuncionario={whatsappDataFuncionario} emailDataInternoFuncionario={emailDataInternoFuncionario} />

                                <LinkPartilhaExpira infoInicialPartilhaProposta={infoInicialPartilhaProposta} />

                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer fixed */}
                {isMobile && (
                    <div className="fixed bottom-0 left-0 w-full h-20 bg-black border-t-2 text-white flex justify-center items-center">
                        {fotoPerfilDataFuncionario ? (
                            <div className='w-full flex justify-between px-6'>
                                <div className="flex gap-2">
                                    {/* <Image
                                        style={{ borderRadius: '50%' }}
                                        width={50}
                                        src={fotoPerfilDataFuncionario}
                                    /> */}
                                    <img
                                        style={{ borderRadius: '50%' }}
                                        className="cursor-pointer"
                                        width={40}
                                        src={fotoPerfilDataFuncionario}
                                        onClick={() => window.open(`${whatsappDataFuncionario}`, '_blank')}
                                    />
                                    <div className="flex flex-col">
                                        <span className="text-sm font-semibold text-white">{nomePerfilDataFuncionario}</span>
                                        <span className="text-base  text-neutral-500">{t('contact_details.popover')}</span>
                                    </div>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <Button type="primary" htmlType="button" onClick={() => window.open(`${whatsappDataFuncionario}`, '_blank')} className="btn-green bg-green-500 text-white rounded flex items-center gap-2">
                                        <FaWhatsapp className='text-2xl' />
                                    </Button>

                                    <Button type="primary" htmlType="button" onClick={() => window.open(`mailto:${emailDataInternoFuncionario}`, '_blank')} className="btn-blue bg-blue-500 text-white rounded flex items-center gap-2">
                                        <HiOutlineMail className='text-2xl' />
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                )}



            </div>
        </div>
    )

}
