import React, { useState } from "react";
import logo from '../../../assets/logo-branco 3.svg';
import logobranco from '../../../assets/logo-branco 2.svg';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle, FaMoon, FaSun } from "react-icons/fa";
import { Button, Modal, Spin, message } from "antd";
import { LoadingOutlined, SmileOutlined } from "@ant-design/icons";
import axios from "axios";



const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


export default function HeaderSimulador({
    isMobile,
    nomePerfilDataFuncionario,
    fotoPerfilDataFuncionario,
    whatsappDataFuncionario,
    emailDataInternoFuncionario,
    isDarkTheme,
    setIsDarkTheme,
    nomeGeradoDaProposta,
    estadoDaProposta,
    setEstadoDaProposta,
    propostaAceiteAprovada,
    setPropostaAceiteAprovada,
    setValidacaoSePodeAceitarProposta
}) {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [loadingPropostaAceite, setLoadingPropostaAceite] = useState(false);


    // console.log(estadoDaProposta)


    const toggleTheme = () => {
        setIsDarkTheme(!isDarkTheme);
        if (!isDarkTheme) {
            document.body.classList.remove('com-bg');
        } else {
            document.body.classList.add('com-bg');
        }
    };

    const handleAceitarProposta = () => {
        setIsModalVisible(true);
    };

    const handleConfirmar = async () => {
        setLoadingPropostaAceite(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_PROPOSTA_ACEITE}`,
                {
                    nome_proposta: nomeGeradoDaProposta,
                }
            );

            // console.log(response)

            if (response.data.sucesso) {
                setIsConfirmed(true);
                setEstadoDaProposta(response.data.estado_proposta)
                setTimeout(() => {
                    setIsModalVisible(false);
                    setIsConfirmed(false);
                }, 4000);
            } else {
                setIsConfirmed(false);
            }
        } catch (error) {
            setIsConfirmed(false);
        } finally {
            setLoadingPropostaAceite(false);
        }
    };


    const handleCancelar = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            {isMobile ? (
                <div className="card grid gap-4 content-start">
                    <div className={`${isDarkTheme ? "text-black" : "text-white"} w-full flex flex-col gap-3`}>
                        <div className="w-full flex items-center justify-between gap-7">
                            <img
                                src={isDarkTheme ? logo : logobranco}
                                alt="Logo"
                                className="w-40 max-w-40 h-8 object-contain cursor-pointer"
                                onClick={() => window.open("https://importrust.com", "_blank")}
                            />
                            <button onClick={toggleTheme} className={`${isDarkTheme ? "bg-black text-white rounded p-2" : "p-2 bg-white text-black rounded"}`}>
                                {isDarkTheme ? <FaMoon /> : <FaSun />}
                            </button>
                        </div>
                        <span className="text-xs">{t('header.phrase')}!</span>
                    </div>
                </div>
            ) : (
                <div className="card flex flex-col sm:flex-row gap-6 items-center justify-between">
                    <div className="flex flex-row gap-4 items-center text-white">
                        <img
                            src={isDarkTheme ? logo : logobranco}
                            alt="Logo"
                            className="w-40 max-w-40 h-10 object-contain cursor-pointer"
                            onClick={() => window.open("https://importrust.com", "_blank")}
                        />
                        <div className={`${isDarkTheme ? "text-black" : "text-white"}`}>|</div>
                        <span className={`${isDarkTheme ? "text-black" : "text-white"} text-base`}>{t('header.phrase')}</span>
                    </div>
                    <div className="flex sm:flex-row flex-col gap-6 items-center">

                        {/* {estadoDaProposta === "proposta_aberta" ? (
                            <Button
                                htmlType="button"
                                onClick={handleAceitarProposta}
                                className={`bg-[#0D9F57] hover:bg-white p-2 rounded flex items-center gap-2 ${isDarkTheme ? "text-black hover:text-white" : "text-white hover:text-white"}`}
                            >
                                Aceitar Proposta
                            </Button>
                        ) : estadoDaProposta === "aceitou_outra_proposta" ? (
                            <div className="bg-orange-400 rounded p-2 text-white">
                                Outra proposta já foi aceite!
                            </div>
                        ) : estadoDaProposta === "proposta_aceite" ? (
                            <div className="bg-[#0D9F57] rounded p-2 text-white">
                                Esta proposta já foi aceite!
                            </div>
                        ) : null} */}

                        {fotoPerfilDataFuncionario ? (
                            <div className="flex gap-2 items-center">
                                <img
                                    style={{ borderRadius: '50%' }}
                                    className="cursor-pointer"
                                    width={50}
                                    src={fotoPerfilDataFuncionario}
                                    onClick={() => window.open(`${whatsappDataFuncionario}`, '_blank')}
                                />
                                <div className="flex flex-col">
                                    <span className={`${isDarkTheme ? "text-black" : "text-white"} text-sm font-semibold cursor-pointer`} onClick={() => window.open(`${whatsappDataFuncionario}`, '_blank')}>{nomePerfilDataFuncionario}</span>
                                    <span className="text-base text-neutral-500">{t('contact_details.popover')}</span>
                                </div>
                            </div>
                        ) : null}

                        <button onClick={toggleTheme} className={`${isDarkTheme ? "bg-black text-white rounded p-2" : "p-2 bg-white text-black rounded"}`}>
                            {isDarkTheme ? <FaMoon /> : <FaSun />}
                        </button>
                    </div>
                </div>
            )}

            {/*Modal de Confirmação */}
            {/* <Modal
                title={!isConfirmed ? "Deseja aceitar a proposta?" : null}
                visible={isModalVisible}
                onCancel={handleCancelar}
                footer={!loadingPropostaAceite && !isConfirmed ? [
                    <Button key="cancel" onClick={handleCancelar}>
                        Fechar
                    </Button>,
                    <Button key="confirm" onClick={handleConfirmar} className={`bg-[#0D9F57] hover:bg-white rounded ${isDarkTheme ? "text-white hover:text-white" : "text-white hover:text-white"}`}>
                        Aceitar Proposta
                    </Button>,
                ] : null}
                closable={!loadingPropostaAceite}
                centered
            >
                {loadingPropostaAceite ? (
                    <div className='flex items-center gap-5 flex-col justify-center text-md p-2.5'>
                        <Spin indicator={antIcon} />
                        <p className='text-black'>Aguarde por favor...</p>
                    </div>
                ) : isConfirmed ? (
                    <div className="grid gap-4 items-center">
                        <FaCheckCircle style={{ fontSize: '48px', color: '#0D9F57' }} />
                        <p className="text-lg font-bold">Proposta Aceite</p>

                        <p>O responsável pelo seu processo irá entrar em contacto para dar seguimento ao processo. Obrigado!</p>

                    </div>
                ) : (
                    <div className='grid gap-2'>
                        <p>Ao aceitar a proposta, o responsável pelo seu processo entrará em contacto consigo.</p>
                    </div>
                )}
            </Modal> */}
        </>
    );
}
