import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import CalculoChaveMao from "../../../components/pt/calculo-chave-mao";
import { Button, Image, Modal, Switch, Spin } from 'antd';
import HeaderSimulador from '../../../components/pt/header/header-simulador';
import TabelaViaturaCliente from '../../../components/pt/tabelaViaturaCliente';
import PT_ResultadodaSimulacao from '../../../components/pt/pt_resultado-da-simulacao';
import LinkPartilhaExpira from '../../../components/pt/link-partilha-expira';
import FuncContactDetails from '../../../components/pt/funcionario/func-contact-details';
import { useTranslation } from 'react-i18next';
import PartilhaRedesProposta from '../../../components/pt/partilha/partilha-redes-proposta';
import { FaWhatsapp } from 'react-icons/fa6';
import { HiOutlineMail } from 'react-icons/hi';
import axios from "axios";
import { FaCheckCircle } from "react-icons/fa";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules';
import CreditoMainComp from '../../../components/pt/credito/credito-main-comp';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { LoadingOutlined } from '@ant-design/icons';

dayjs.extend(customParseFormat);


const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);



export default function PT_Calculo({
    nomeGeradoDaProposta,
    dataCarros,
    temParametros,
    formatarNumero,
    isDarkTheme,
    currentLanguage,
    setIsDarkTheme
}) {


    const { t } = useTranslation();


    const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
    })


    //outros
    // const [loading, setLoading] = useState(false);
    const [alertasNoPedido, setAlertasNoPedido] = useState(false);

    //guardar dados do carro

    //guardar titulo do copypasy da proposta
    const [tituloDaPropostaChaveNaMao, setTituloDaPropostaChaveNaMao] = useState('');

    //para ISV
    const [tipoDeVeiculoParaInsencoes, setTipoDeVeiculoParaInsencoes] = useState('');
    const [tipoDeVeiculoParaOutros, setTipoDeVeiculoParaOutros] = useState('LigeiroPassageiros');
    const [novoOuUsado, setNovoOuUsado] = useState('');
    const [dataDeRegisto, setDataDeRegisto] = useState('');
    const [cilindrada, setCilindrada] = useState('');
    const [tipoDeCombustivel, setTipoDeCombustivel] = useState('');
    const [particulas, setParticulas] = useState('');
    const [tipoDeTestes, setTipoDeTestes] = useState('');
    const [emissoesGases, setEmissoesGases] = useState('');

    //para calculos
    const [taxaDeServico, setTaxaDeServico] = useState('2800');
    const [precoFinalCarro, setPrecoFinalCarro] = useState('');
    const [valorFinalISV, setValorFinalISV] = useState('0');
    const [dataGeralISV, setDataGeralISV] = useState('');

    const [valorFinalIUC, setValorFinalIUC] = useState('0');
    const [dataGeralIUC, setDataGeralIUC] = useState('');
    const [iucCilindrada, setIucCilindrada] = useState('');
    const [iucCO2, setIucCO2] = useState('');
    const [iucAdicionalCO2, setIucAdicionalCO2] = useState('');
    const [iucCoeficienteAnoMatriculo, setIucCoeficienteAnoMatriculo] = useState('');
    const [iucAdicionalGasoleo, setIucAdicionalGasoleo] = useState('');

    const [isvcomponentecilindrada, setIsvComponenteCilindrada] = useState('');
    const [isvcomponenteambiental, setIsvcomponenteambiental] = useState('');
    const [isvReducaoAnosUsoCilindrada, setIsvReducaoAnosUsoCilindrada] = useState('');
    const [isvReducaoAnosUsoAmbiental, setIsvReducaoAnosUsoAmbiental] = useState('');
    const [isvAgravamentoParticulas, setIsvAgravamentoParticulas] = useState('');
    const [isvReducaoAnosUsoParticulas, setIsvReducaoAnosUsoParticulas] = useState('');
    const [isvTaxaAplicavelTabela, setIsvTaxaAplicavelTabela] = useState('');


    const [valorChaveNaMaoEmpresa, setValorChaveNaMaoEmpresa] = useState('');
    const [valorChaveNaMaoParticular, setValorChaveNaMaoParticular] = useState('');


    //Dados - Validar Dados - Guardar Dados
    const [quantidadeDeKM, setQuantidadeDeKM] = useState('');
    const [anoDoRegisto, setAnoDoRegisto] = useState('');
    const [precoFinalDoCarroSemIva, setPrecoFinalDoCarroSemIva] = useState('');
    const [precoFinalDoCarroIvaDedutival, setPrecoFinalDoCarroIvaDedutival] = useState(false);
    //const [selecionarIsencao, setSelecionarIsencao] = useState(true);
    const [hibridoIsChecked, setHibridoIsChecked] = useState(false);
    const [co2IsChecked, setCo2IsChecked] = useState(false);
    const [wltpIsChecked, setWltpIsChecked] = useState(false);
    const [outrosServicos, setOutrosServicos] = useState('');
    const [validarAnoDeRegisto, setValidarAnoDeRegisto] = useState(false);
    const [validarViaturaEletrico, setValidarViaturaEletrico] = useState(false);
    // VALIDACAO HIBRIDO PLUGIN
    const [hibridoValidationIsChecked, setHibridoValidationIsChecked] = useState(false);
    // PARA SABER SE É TAB PARTICULAR OU EMPRESA (PARTICULAR COMEÇA COM TRUE)
    const [isParticular, setIsParticular] = useState(true);

    const [erroInicial, setErroInicial] = useState('Ocorreu algum erro, tente novamente!');
    const [infoInicialPartilhaProposta, setInfoInicialPartilhaProposta] = useState('');

    //Resultado da Simulação
    const [activeTabKeyResultadoSimulacao, setActiveTabKeyResultadoSimulacao] = useState("1");
    const [tabResultadoSimulacao, setTabResultadoSimulacao] = useState("0");


    //partilhar com cliente
    const [allDataLinkPartilhado, setAllDataLinkPartilhado] = useState('');

    const [nomePerfilDataFuncionario, setNomePerfilDataFuncionario] = useState('');
    const [fotoPerfilDataFuncionario, setFotoPerfilDataFuncionario] = useState('');
    const [whatsappDataFuncionario, setWhatsappDataFuncionario] = useState('');
    const [emailDataInternoFuncionario, setEmailDataInternoFuncionario] = useState('');

    const [IgnorarAvisos, setIgnorarAvisos] = useState(false);







    const [isModalOpen, setIsModalOpen] = useState(false);



    const [activeTabKeySimulacaoCredito, setActiveTabKeySimulacaoCredito] = useState("1");
    const [taxaDeJuroCredito, setTaxaDeJuroCredito] = useState('6.50');
    const [entradaCredito, setEntradaCredito] = useState('');
    const [valorChaveNaMaoViaturaParaCredito, setValorChaveNaMaoViaturaParaCredito] = useState('');
    const [valorFinanciadoCredito, setValorFinanciadoCredito] = useState('');
    const [montanteNecessarioCredito, setMontanteNecessarioCredito] = useState('');
    const [prestacoesMesesCredito, setPrestacoesMesesCredito] = useState('');
    const [valorChaveNaMaoParaCredito, setValorChaveNaMaoParaCredito] = useState('');
    const [valorFinalCredito, setValorFinalCredito] = useState('');
    const [ivaNaOrigem, setIvaNaOrigem] = useState(false);
    const [valorIvaNaOrigem, setValorIvaNaOrigem] = useState('');

    //Secção do Crédito
    const [isCheckedCredito, setIsCheckedCredito] = useState(false);
    const [mostrarResultado, setMostrarResultado] = useState(false);
    const [comissaoCredito, setComissaoCredito] = useState('400');
    const [valorDaViatura, setValorDaViatura] = useState('');
    const [valorFinalaoCliente, setValorFinalaoCliente] = useState('');
    const [valorPedirAFabi, setValorPedirAFabi] = useState('');
    const [percentagemPedirAFabi, setPercentagemPedirAFabi] = useState('20');

    //estado para guardar se a proposta foi aprovada
    // const [propostaAceiteAprovada, setPropostaAceiteAprovada] = useState('');
    // const [validacaoSePodeAceitarProposta, setValidacaoSePodeAceitarProposta] = useState('');
    const [tipoDaProposta, setTipoDaProposta] = useState('');
    const [estadoDaProposta, setEstadoDaProposta] = useState('');


    // //valida se tem parametros na url
    useEffect(() => {

        if (dataCarros) {

            const whatsappFuncionario = dataCarros?.data?.user.whatsapp
            const emailInternoFuncionario = dataCarros?.data?.user.email_interno
            const fotoPerfilInternoFuncionario = dataCarros?.data?.user.foto_perfil
            const nomePerfilInternoFuncionario = dataCarros?.data?.user.nome_user
            setNomePerfilDataFuncionario(nomePerfilInternoFuncionario)
            setFotoPerfilDataFuncionario(fotoPerfilInternoFuncionario)
            setWhatsappDataFuncionario(whatsappFuncionario)
            setEmailDataInternoFuncionario(emailInternoFuncionario)



            const dataLinkPartilhado = dataCarros.data.data
            // console.log(dataLinkPartilhado)

            // setValidacaoSePodeAceitarProposta(dataLinkPartilhado.pode_aceitar_proposta)
            // setPropostaAceiteAprovada(dataLinkPartilhado.proposta_aceite)
            setTipoDaProposta(dataCarros.data.tipo_proposta)
            setEstadoDaProposta(dataCarros.data.estado_proposta)

            setAllDataLinkPartilhado(dataLinkPartilhado)
            processosRepetidosNosPedidos(dataLinkPartilhado);
            setValorFinalISV(dataLinkPartilhado.isv)
            setValorFinalIUC(dataLinkPartilhado.iuc)

            setIucCilindrada(dataLinkPartilhado.iuc_cilindrada)
            setIucCO2(dataLinkPartilhado.iuc_co2)
            setIucAdicionalCO2(dataLinkPartilhado.iuc_adicional_co2)
            setIucCoeficienteAnoMatriculo(dataLinkPartilhado.iuc_coeficiente_ano_matriculo)
            setIucAdicionalGasoleo(dataLinkPartilhado.iuc_adicional_gasoleo)

            // console.log(dataLinkPartilhado)
            setIsvComponenteCilindrada(dataLinkPartilhado.isv_componente_cilindrada)
            setIsvcomponenteambiental(dataLinkPartilhado.isv_componente_ambiental)
            setIsvReducaoAnosUsoCilindrada(dataLinkPartilhado.isv_reducao_anos_uso_cilindrada)
            setIsvReducaoAnosUsoAmbiental(dataLinkPartilhado.isv_reducao_anos_uso_ambiental)
            setIsvAgravamentoParticulas(dataLinkPartilhado.isv_agravamento_particulas)
            setIsvReducaoAnosUsoParticulas(dataLinkPartilhado.isv_reducao_anos_uso_particulas)
            setIsvTaxaAplicavelTabela(dataLinkPartilhado.isv_taxa_aplicavel_tabela)


            setTaxaDeServico(dataLinkPartilhado.taxadeservico)
            setInfoInicialPartilhaProposta(dataCarros.data.data_expira)
            setIgnorarAvisos(true);
            // setTabResultadoSimulacao(dataCarros.data.data.empresa)


            if (dataLinkPartilhado.tipo_de_combustivel === "Eletrico") {
                setValidarViaturaEletrico(true)
            } else {
                setValidarViaturaEletrico(false)
            }

        }

    }, [dataCarros]);



    // CODIGO QUE SEJA EXATAMENTE O MESMO PARA OS DOIS PEDIDOS (AUTOSCOUT24 E MOBILE.DE ou + no futuro)
    function processosRepetidosNosPedidos(dataFinalDadosObjeto) {

        // console.log(dataFinalDadosObjeto.wltp)



        if (dataFinalDadosObjeto) {
            // setDataCarros(dataFinalDadosObjeto)

            if (dataFinalDadosObjeto.novo_ou_usado === "1") {
                setNovoOuUsado('Novo')
            } else {
                setNovoOuUsado('Usado')
            }


            // Tipo de Combustivel
            setTipoDeCombustivel(dataFinalDadosObjeto.tipo_combustivel_traduzido)

            // CILINDRADA
            setCilindrada(dataFinalDadosObjeto.cilindrada)

            // EMISSOES
            setEmissoesGases(dataFinalDadosObjeto.emissoes_numerico)

            // Data primeiro Registo
            setDataDeRegisto(dataFinalDadosObjeto.data_registo ? dataFinalDadosObjeto.data_registo : '')


            // TIPO DE TESTES:
            setTipoDeTestes('NEDC');
            setValidarAnoDeRegisto(false)
            setAnoDoRegisto(false)

            // PARA CIMA DE 10/2018 - WLTP POR DEFEITO
            let data_registo = dataFinalDadosObjeto.data_registo;

            // PRECO DA VIATURA (COM IVA)
            const precoViatura = dataFinalDadosObjeto.preco_final_carro ? dataFinalDadosObjeto.preco_final_carro : "";
            setPrecoFinalCarro(precoViatura)


            setPrecoFinalDoCarroIvaDedutival(false)
            let precoViaturaSemIva = precoViatura;

            // console.log(dataFinalDadosObjeto.wltp === 1)

            if (dataFinalDadosObjeto.wltp === 1) {
                setWltpIsChecked(true)
            } else {
                setWltpIsChecked(false)
            }

            if (dataFinalDadosObjeto.preco_final_carro_sem_iva) { // SE EXISTIR SEM IVA
                setPrecoFinalDoCarroIvaDedutival(true)
                precoViaturaSemIva = dataFinalDadosObjeto.preco_final_carro_sem_iva;
            }
            setPrecoFinalDoCarroSemIva(precoViaturaSemIva)

            //validar a quantidade de KM
            let quantidadeKM = dataFinalDadosObjeto.quantidade_de_km ? dataFinalDadosObjeto.quantidade_de_km : "";

            if (quantidadeKM) {
                quantidadeKM = parseFloat(quantidadeKM.toString().replace(",", ""));
            }

            let tipo_combustivel_raw = dataFinalDadosObjeto.tipo_de_combustivel ? dataFinalDadosObjeto.tipo_de_combustivel : "";
            let tipo_combustivel = dataFinalDadosObjeto.tipo_combustivel_traduzido ? dataFinalDadosObjeto.tipo_combustivel_traduzido : "";


            //validar hibrido
            if (tipo_combustivel_raw === "Hybrid (petrol/electric)") {
                setHibridoIsChecked(true)
                setValorFinalISV('0€')
                setCo2IsChecked(false)
                setTipoDeCombustivel('Gasolina')

            } else if (tipo_combustivel_raw === "Hybrid (diesel/electric)") {
                setHibridoIsChecked(true)
                setValorFinalISV('0€')
                setCo2IsChecked(false)
                setTipoDeCombustivel('Gasoleo')
                tipo_combustivel = 'Gasoleo'
            } else {
                setHibridoIsChecked(false)
            }


            // DEFINIR PARTICULAS SE FOR GASOLEO OU GASOLINA
            if (tipo_combustivel.toLowerCase() == "gasoleo") {
                setParticulas('1')
            } else {
                setParticulas('0')
            }



        }

    }


    useEffect(() => {


        let chaveNaMaoValue = ""
        let precoFinalCarroCal = ""

        let taxaDeServicoCal = parseFloat(taxaDeServico);
        let valorFinalISVCal = parseFloat(valorFinalISV);

        if (isNaN(taxaDeServicoCal)) {
            taxaDeServicoCal = 0;
        }

        // Particular
        precoFinalCarroCal = parseFloat(precoFinalCarro);
        if (!isNaN(precoFinalCarroCal) && !isNaN(valorFinalISVCal)) {
            chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal + valorFinalISVCal;
            setValorChaveNaMaoParticular(chaveNaMaoValue);
        } else {
            chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal;
            setValorChaveNaMaoParticular(chaveNaMaoValue);
        }

        // EMPRESA
        precoFinalCarroCal = parseFloat(precoFinalDoCarroSemIva);
        if (!isNaN(precoFinalCarroCal) && !isNaN(valorFinalISVCal)) {
            chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal + valorFinalISVCal;
            setValorChaveNaMaoEmpresa(chaveNaMaoValue);
        } else {
            chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal;
            setValorChaveNaMaoEmpresa(chaveNaMaoValue);
        }


    }, [precoFinalCarro, taxaDeServico, valorFinalISV]);




    const [tituloDoMobileDaViatura, setTituloDoMobileDaViatura] = useState('');


    useEffect(() => {

        setTituloDoMobileDaViatura(allDataLinkPartilhado?.titulo)

    }, [allDataLinkPartilhado]);



    // const showModal = () => {
    //     setIsModalOpen(true);
    // };

    // const handleOk = () => {
    //     setIsModalOpen(false);
    // };

    // const handleCancel = () => {
    //     setIsModalOpen(false);
    // };


    // const handleCredito = (checked) => {
    //     setIsCheckedCredito(checked);
    // };





    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [loadingPropostaAceite, setLoadingPropostaAceite] = useState(false);



    const handleAceitarProposta = () => {
        setIsModalVisible(true);
    };



    const handleConfirmar = async () => {
        setLoadingPropostaAceite(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_PROPOSTA_ACEITE}`,
                {
                    nome_proposta: nomeGeradoDaProposta,
                }
            );

            // console.log(response)

            if (response.data.sucesso) {
                setIsConfirmed(true);
                setEstadoDaProposta(response.data.estado_proposta)
                setTimeout(() => {
                    setIsModalVisible(false);
                    setIsConfirmed(false);
                }, 4000);
            } else {
                setIsConfirmed(false);
            }
        } catch (error) {
            setIsConfirmed(false);
        } finally {
            setLoadingPropostaAceite(false);
        }
    };


    const handleCancelar = () => {
        setIsModalVisible(false);
    };




    return (

        <>
            <div className='w-full flex flex-col justify-center items-center gap-4 p-2 sm:p-4'>
                <div className='w-full h-full flex flex-col gap-2 md:gap-4'>

                    {/* Header sticky */}
                    <div className='w-full'>
                        <HeaderSimulador
                            isMobile={isMobile}
                            nomePerfilDataFuncionario={nomePerfilDataFuncionario}
                            fotoPerfilDataFuncionario={fotoPerfilDataFuncionario}
                            whatsappDataFuncionario={whatsappDataFuncionario}
                            emailDataInternoFuncionario={emailDataInternoFuncionario}
                            isDarkTheme={isDarkTheme}
                            setIsDarkTheme={setIsDarkTheme}
                            nomeGeradoDaProposta={nomeGeradoDaProposta}
                            estadoDaProposta={estadoDaProposta}
                            setEstadoDaProposta={setEstadoDaProposta}
                        // validacaoSePodeAceitarProposta={validacaoSePodeAceitarProposta}
                        // propostaAceiteAprovada={propostaAceiteAprovada}
                        // setPropostaAceiteAprovada={setPropostaAceiteAprovada}
                        // setValidacaoSePodeAceitarProposta={setValidacaoSePodeAceitarProposta}
                        />
                    </div>

                    {/* Main content section scrollable */}
                    <div className={`${isDarkTheme ? "text-black" : "text-white"} w-full flex-1 overflow-y-auto`} style={{ paddingBottom: isMobile ? '90px' : '0' }}>
                        <div className='grid gap-4'>
                            <div className='flex flex-col sm:flex-row gap-4 w-full h-full'>
                                <div className='w-full sm:w-3/5 grid gap-4'>
                                    <div className='card'>
                                        <TabelaViaturaCliente
                                            isMobile={isMobile}
                                            nomeGeradoDaProposta={nomeGeradoDaProposta}
                                            allDataLinkPartilhado={allDataLinkPartilhado}
                                            formatarNumero={formatarNumero}
                                            dataCarros={dataCarros}
                                            isParticular={isParticular}
                                            isDarkTheme={isDarkTheme}
                                            currentLanguage={currentLanguage}
                                        />
                                    </div>
                                </div>
                                <div className="w-full sm:w-2/5 flex flex-col gap-4">
                                    <CalculoChaveMao
                                        allDataLinkPartilhado={allDataLinkPartilhado}
                                        validarViaturaEletrico={validarViaturaEletrico}
                                        temParametros={temParametros}
                                        dataDeRegisto={dataDeRegisto}
                                        novoOuUsado={novoOuUsado}
                                        tipoDeCombustivel={tipoDeCombustivel}
                                        setTaxaDeServico={setTaxaDeServico}
                                        tipoDeVeiculoParaOutros={tipoDeVeiculoParaOutros}
                                        hibridoIsChecked={hibridoIsChecked}
                                        setCo2IsChecked={setCo2IsChecked}
                                        setHibridoValidationIsChecked={setHibridoValidationIsChecked}
                                        setNovoOuUsado={setNovoOuUsado}
                                        setTituloDaPropostaChaveNaMao={setTituloDaPropostaChaveNaMao}
                                        setOutrosServicos={setOutrosServicos}
                                        setAlertasNoPedido={setAlertasNoPedido}
                                        setCilindrada={setCilindrada}
                                        setDataDeRegisto={setDataDeRegisto}
                                        setEmissoesGases={setEmissoesGases}
                                        setHibridoIsChecked={setHibridoIsChecked}
                                        setTipoDeCombustivel={setTipoDeCombustivel}
                                        setParticulas={setParticulas}
                                        setTipoDeVeiculoParaOutros={setTipoDeVeiculoParaOutros}
                                        setWltpIsChecked={setWltpIsChecked}
                                        setTipoDeVeiculoParaInsencoes={setTipoDeVeiculoParaInsencoes}
                                        alertasNoPedido={alertasNoPedido}
                                        taxaDeServico={taxaDeServico}
                                        tipoDeVeiculoParaInsencoes={tipoDeVeiculoParaInsencoes}
                                        hibridoValidationIsChecked={hibridoValidationIsChecked}
                                        outrosServicos={outrosServicos}
                                        cilindrada={cilindrada}
                                        emissoesGases={emissoesGases}
                                        wltpIsChecked={wltpIsChecked}
                                        particulas={particulas}
                                        validarAnoDeRegisto={validarAnoDeRegisto}
                                        co2IsChecked={co2IsChecked}
                                        valorFinalISV={valorFinalISV}
                                        dataGeralISV={dataGeralISV}
                                        quantidadeDeKM={quantidadeDeKM}
                                        anoDoRegisto={anoDoRegisto}
                                        IgnorarAvisos={IgnorarAvisos}
                                        isDarkTheme={isDarkTheme}
                                    />
                                    <PT_ResultadodaSimulacao
                                        valorChaveNaMaoEmpresa={valorChaveNaMaoEmpresa}
                                        activeTabKeyResultadoSimulacao={activeTabKeyResultadoSimulacao}
                                        setActiveTabKeyResultadoSimulacao={setActiveTabKeyResultadoSimulacao}
                                        formatarNumero={formatarNumero}
                                        valorFinalIUC={valorFinalIUC}
                                        valorFinalISV={valorFinalISV}
                                        iucCilindrada={iucCilindrada}
                                        iucCO2={iucCO2}
                                        iucAdicionalCO2={iucAdicionalCO2}
                                        iucCoeficienteAnoMatriculo={iucCoeficienteAnoMatriculo}
                                        iucAdicionalGasoleo={iucAdicionalGasoleo}
                                        isvcomponentecilindrada={isvcomponentecilindrada}
                                        isvcomponenteambiental={isvcomponenteambiental}
                                        isvReducaoAnosUsoCilindrada={isvReducaoAnosUsoCilindrada}
                                        isvReducaoAnosUsoAmbiental={isvReducaoAnosUsoAmbiental}
                                        isvAgravamentoParticulas={isvAgravamentoParticulas}
                                        isvReducaoAnosUsoParticulas={isvReducaoAnosUsoParticulas}
                                        isvTaxaAplicavelTabela={isvTaxaAplicavelTabela}
                                        dataGeralIUC={dataGeralIUC}
                                        taxaDeServico={taxaDeServico}
                                        tituloDaPropostaChaveNaMao={tituloDaPropostaChaveNaMao}
                                        precoFinalCarro={precoFinalCarro}
                                        valorChaveNaMaoParticular={valorChaveNaMaoParticular}
                                        temParametros={temParametros}
                                        setTituloDaPropostaChaveNaMao={setTituloDaPropostaChaveNaMao}
                                        dataCarros={dataCarros}
                                        precoFinalDoCarroSemIva={precoFinalDoCarroSemIva}
                                        setTabResultadoSimulacao={setTabResultadoSimulacao}
                                        tabResultadoSimulacao={tabResultadoSimulacao}
                                        isDarkTheme={isDarkTheme}
                                    />



                                    {/* <div>
                                        <div className='card'>
                                            <div className='titulo-form'>
                                                <p className='font-semibold'>Simulação de crédito</p>
                                            </div>
                                            <div>
                                                <label className='flex gap-2 items-center cursor-pointer'>
                                                    <Switch checked={isCheckedCredito} onChange={handleCredito} />
                                                    <span>Deseja avançar com financiamento?</span>
                                                </label>
                                            </div>
                                            {isCheckedCredito && (
                                                <div className='grid gap-3 mt-4'>
                                                    <CreditoMainComp
                                                        setComissaoCredito={setComissaoCredito}
                                                        comissaoCredito={comissaoCredito}
                                                        mostrarResultado={mostrarResultado}
                                                        setMostrarResultado={setMostrarResultado}
                                                        formatarNumero={formatarNumero}
                                                        taxaDeJuroCredito={taxaDeJuroCredito}
                                                        precoFinalCarro={precoFinalCarro}
                                                        precoFinalDoCarroSemIva={precoFinalDoCarroSemIva}
                                                        entradaCredito={entradaCredito}
                                                        setEntradaCredito={setEntradaCredito}
                                                        valorFinanciadoCredito={valorFinanciadoCredito}
                                                        setValorFinanciadoCredito={setValorFinanciadoCredito}
                                                        prestacoesMesesCredito={prestacoesMesesCredito}
                                                        setPrestacoesMesesCredito={setPrestacoesMesesCredito}
                                                        ivaNaOrigem={ivaNaOrigem}
                                                        valorFinalCredito={valorFinalCredito}
                                                        setValorFinalCredito={setValorFinalCredito}
                                                        valorIvaNaOrigem={valorIvaNaOrigem}
                                                        activeTabKeyResultadoSimulacao={activeTabKeyResultadoSimulacao}
                                                        activeTabKeySimulacaoCredito={activeTabKeySimulacaoCredito}
                                                        setActiveTabKeySimulacaoCredito={setActiveTabKeySimulacaoCredito}
                                                        setTabResultadoSimulacao={setTabResultadoSimulacao}
                                                        valorFinalISV={valorFinalISV}
                                                        taxaDeServico={taxaDeServico}
                                                        valorChaveNaMaoParticular={valorChaveNaMaoParticular}
                                                        valorChaveNaMaoEmpresa={valorChaveNaMaoEmpresa}
                                                        valorChaveNaMaoParaCredito={valorChaveNaMaoParaCredito}
                                                        setValorChaveNaMaoParaCredito={setValorChaveNaMaoParaCredito}
                                                        valorDaViatura={valorDaViatura}
                                                        setValorDaViatura={setValorDaViatura}
                                                        valorFinalaoCliente={valorFinalaoCliente}
                                                        setValorFinalaoCliente={setValorFinalaoCliente}
                                                        valorPedirAFabi={valorPedirAFabi}
                                                        setValorPedirAFabi={setValorPedirAFabi}
                                                        percentagemPedirAFabi={percentagemPedirAFabi}
                                                        setPercentagemPedirAFabi={setPercentagemPedirAFabi}
                                                    />
                                                    <div className='flex flex-col items-center gap-2 text-md border-card bg-black'>
                                                        <AiOutlineInfoCircle /> <span className='text-center text-sm pl-10 pr-10'>Se desejar avançar com o financiamento, entre em contacto!</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div> */}


                                    <div className='card'>
                                        <PartilhaRedesProposta isDarkTheme={isDarkTheme} tituloDoMobileDaViatura={tituloDoMobileDaViatura} nomeGeradoDaProposta={nomeGeradoDaProposta} />
                                    </div>


                                    <FuncContactDetails isDarkTheme={isDarkTheme} nomePerfilDataFuncionario={nomePerfilDataFuncionario} fotoPerfilDataFuncionario={fotoPerfilDataFuncionario} whatsappDataFuncionario={whatsappDataFuncionario} emailDataInternoFuncionario={emailDataInternoFuncionario} />

                                    {/* <div className='card flex justify-center'>
                                        {estadoDaProposta === "proposta_aberta" ? (
                                            <Button
                                                htmlType="button"
                                                onClick={handleAceitarProposta}
                                                className={`bg-[#0D9F57] hover:bg-white p-2 rounded flex items-center gap-2 ${isDarkTheme ? "text-black hover:text-white" : "text-white hover:text-white"}`}
                                            >
                                                Aceitar Proposta
                                            </Button>
                                        ) : estadoDaProposta === "aceitou_outra_proposta" ? (
                                            <div className="bg-orange-400 rounded p-2 text-white">
                                                Outra proposta já foi aceite!
                                            </div>
                                        ) : estadoDaProposta === "proposta_aceite" ? (
                                            <div className="bg-[#0D9F57] rounded p-2 text-white">
                                                Esta proposta já foi aceite!
                                            </div>
                                        ) : null}
                                    </div> */}

                                    <LinkPartilhaExpira isDarkTheme={isDarkTheme} infoInicialPartilhaProposta={infoInicialPartilhaProposta} />
                                </div>
                            </div>
                        </div>
                    </div>


                    {isMobile && (
                        <>
                            <div className="fixed bottom-0 left-0 w-full h-20 bg-black border-t-2 text-white flex justify-center items-center">
                                {fotoPerfilDataFuncionario ? (
                                    <div className='w-full flex justify-between px-6'>
                                        <div className="flex gap-2 items-center">
                                            <img
                                                style={{ borderRadius: '50%' }}
                                                className="cursor-pointer"
                                                width={40}
                                                src={fotoPerfilDataFuncionario}
                                                onClick={() => window.open(`${whatsappDataFuncionario}`, '_blank')}
                                            />
                                            <div className="flex flex-col">
                                                <span className="text-sm font-semibold text-white" onClick={() => window.open(`${whatsappDataFuncionario}`, '_blank')}>{nomePerfilDataFuncionario}</span>
                                                <span className="text-base  text-neutral-500">{t('contact_details.popover')}</span>
                                            </div>
                                        </div>
                                        {/* <div className='flex items-center gap-2'>
                                            {estadoDaProposta === "proposta_aberta" ? (
                                                <Button
                                                    htmlType="button"
                                                    onClick={handleAceitarProposta}
                                                    className={`bg-[#0D9F57] hover:bg-white p-2 rounded flex items-center gap-2 ${isDarkTheme ? "text-black hover:text-white" : "text-white hover:text-white"}`}
                                                >
                                                    Aceitar Proposta
                                                </Button>
                                            ) : estadoDaProposta === "aceitou_outra_proposta" ? (
                                                <div className="bg-orange-400 rounded p-2 text-white text-center">
                                                    <span className='text-sm'>Outra proposta já foi aceite!</span>
                                                </div>
                                            ) : estadoDaProposta === "proposta_aceite" ? (
                                                <div className="bg-[#0D9F57] rounded p-2 text-white text-center">
                                                    <span className='text-sm'>Esta proposta já foi aceite!</span>
                                                </div>
                                            ) : null}
                                        </div> */}
                                    </div>
                                ) : null}
                            </div>
                        </>

                    )}


                    {/* <Modal
                        title={!isConfirmed ? "Deseja aceitar a proposta?" : null}
                        visible={isModalVisible}
                        onCancel={handleCancelar}
                        footer={!loadingPropostaAceite && !isConfirmed ? [
                            <Button key="cancel" onClick={handleCancelar}>
                                Fechar
                            </Button>,
                            <Button key="confirm" onClick={handleConfirmar} className={`bg-[#0D9F57] hover:bg-white rounded ${isDarkTheme ? "text-white hover:text-white" : "text-white hover:text-white"}`}>
                                Aceitar Proposta
                            </Button>,
                        ] : null}
                        closable={!loadingPropostaAceite}
                        centered
                    >
                        {loadingPropostaAceite ? (
                            <div className='flex items-center gap-5 flex-col justify-center text-md p-2.5'>
                                <Spin indicator={antIcon} />
                                <p className='text-black'>Aguarde por favor...</p>
                            </div>
                        ) : isConfirmed ? (
                            <div className="grid gap-4 items-center">
                                <FaCheckCircle style={{ fontSize: '38px', color: '#0D9F57' }} />
                                <p className="text-lg font-bold">Proposta Aceite</p>

                                <p>O responsável pelo seu processo irá entrar em contacto para dar seguimento ao processo. Obrigado!</p>

                            </div>
                        ) : (
                            <div className='grid gap-2'>
                                <p>Ao aceitar a proposta, o responsável pelo seu processo entrará em contacto consigo.</p>
                            </div>
                        )}
                    </Modal> */}

                </div>
            </div >

        </>
    )

}
