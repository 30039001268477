import React from "react";
import { Link } from 'react-router-dom';
import { BiError } from "react-icons/bi";
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';


export default function ErroPagina({ isDarkTheme }) {

    const { t } = useTranslation();

    return (



        <div className="sec-geral-top login-section card">
            <div className="form main flex flex-col gap-4">
                <div className="acesso-negado items-center flex flex-col gap-5">
                    <BiError className='iconc' />
                    <div className="flex-nogap">
                        <h1 className="text-center text-base font-bold">UPS...</h1>
                        <p className="texto-centro">{t('pagina_erro.frase_principal')}</p>
                    </div>
                    <Link to="/">
                        <Button type="primary" className={`${isDarkTheme ? "bg-white text-black" : "bg-black text-white"} font-bold rounded`}>
                            {t('pagina_erro.cta')}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>


    )
}
