import React from "react";
import { Input, Select, Button, Checkbox, Collapse, Tooltip } from 'antd';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker } from 'antd';
import { Option } from "antd/lib/mentions";


dayjs.extend(customParseFormat);

//const { Option } = Select;
const { Panel } = Collapse;

export default function ES_CalculoChaveMao({
    idCidade,
    listaDeModelos,
    valorViaturaNova,
    setValorViaturaNova,
    idMarca,
    setIdMarca,
    setIdCidade,
    setIdModelo,
    idModelo,
    listaDeMarcas,
    idMunicipio,
    setIdMunicipio,
    listaDeMunicipios,
    allDataLinkPartilhado,
    validarViaturaEletrico,
    temParametros,
    dataDeRegisto,
    novoOuUsado,
    tipoDeCombustivel,
    setTaxaDeServico,
    tipoDeVeiculoParaOutros,
    hibridoIsChecked,
    setCo2IsChecked,
    setHibridoValidationIsChecked,
    setNovoOuUsado,
    setTituloDaPropostaChaveNaMao,
    setOutrosServicos,
    setAlertasNoPedido,
    setCilindrada,
    setDataDeRegisto,
    setEmissoesGases,
    setHibridoIsChecked,
    setTipoDeCombustivel,
    setParticulas,
    setTipoDeVeiculoParaOutros,
    setWltpIsChecked,
    setTipoDeVeiculoParaInsencoes,
    alertasNoPedido,
    taxaDeServico,
    tipoDeVeiculoParaInsencoes,
    hibridoValidationIsChecked,
    outrosServicos,
    cilindrada,
    emissoesGases,
    wltpIsChecked,
    particulas,
    validarAnoDeRegisto,
    co2IsChecked,
    valordaViaturaIsChecked,
    setValordaViaturaIsChecked,
    valorFinalISV,
    quantidadeDeKM,
    anoDoRegisto,
    IgnorarAvisos,
    listaDeCidades,
    dataIdMarca,
    dataIdModelo,
    dataIdCidade,
    dataIdMunicipio
}) {


    return (

        <div className='card'>
            <div className='titulo-form'>
                <p className="font-semibold">Cálculo do IVTM</p>
            </div>
            <form className=' grid  gap-5'>
                <div>
                    <div className="mx-auto grid gap-2">

                        <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>

                            <div className="grid gap-1 content-start">
                                <p className='text-sm text-neutral-500 font-semibold'>Provincias</p>
                                <span>{dataIdCidade ? dataIdCidade : ""}</span>

                            </div>

                            <div className="grid gap-1 content-start">
                                <p className='text-sm text-neutral-500 font-semibold'>Munícipio</p>
                                <span>{dataIdMunicipio ? dataIdMunicipio : ""}</span>

                            </div>

                            <div className="grid gap-1 content-start">
                                <p className='text-sm text-neutral-500 font-semibold'>Nuevo / Usado</p>
                                <span>{novoOuUsado ? novoOuUsado : undefined}</span>
                            </div>

                            {novoOuUsado === "Usado" ? (
                                <div className='grid gap-2'>
                                    <div className='grid gap-1'>
                                        <p className='text-sm text-neutral-500 font-semibold'>Fecha de primer registro</p>
                                        <span>{dataDeRegisto}</span>
                                    </div>
                                </div>
                            ) : null}


                            <div className="grid gap-1 content-start">
                                <p className='text-sm text-neutral-500 font-semibold'>Marca</p>
                                <span>{dataIdMarca ? dataIdMarca : "Selecciona"}</span>
                            </div>

                            <div className="grid gap-1 content-start">
                                <p className='text-sm text-neutral-500 font-semibold'>Modelo</p>
                                <span>{dataIdModelo ? dataIdModelo : "Selecciona"}</span>
                            </div>


                            <div className="grid gap-1 content-start">
                                <p className='text-sm text-neutral-500 font-semibold'>Valor del Vehículo Nuevo</p>
                                <span>{valorViaturaNova}</span>
                            </div>

                            {tipoDeCombustivel !== "Eletrico" && (
                                <div className="grid gap-1 content-start">
                                    <p className='text-sm text-neutral-500 font-semibold'>Cilindrada</p>
                                    <span>{cilindrada}</span>
                                </div>
                            )}

                            <div className="grid gap-1 content-start">
                                <p className='text-sm text-neutral-500 font-semibold'>Tipo de Combustible</p>
                                <span>{tipoDeCombustivel ? tipoDeCombustivel : undefined}</span>
                            </div>


                            {tipoDeCombustivel !== "Eletrico" && (
                                <div className="grid gap-1 content-start">
                                    <p className='text-sm text-neutral-500 font-semibold'>Emisiones de Gases CO2</p>
                                    <span>{emissoesGases}</span>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}