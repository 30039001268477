import React from 'react';
import {
    Routes,
    Route
} from "react-router-dom";
import PT_Calculo from './routes/pt/calculo/pt_calculo';
import ES_Calculo from './routes/es/calculo/es_calculo';
import ErroPagina from './routes/erropagina';


export function AppRoutes({
    temParametros,
    dataCarros,
    nomeGeradoDaProposta,
    isDarkTheme,
    setIsDarkTheme,
    currentLanguage
}) {


    function formatarNumero(number, decimalPlaces = 0, thousandsSeparator = ' ', decimalSeparator = '.') {

        number = parseFloat(number);

        if (number < 10000) {
        }

        const formattedValue = number.toLocaleString('en-US', {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        });

        // SEPARAR NAS DECIMAIS
        let parts = formattedValue.split('.');

        let integerPart = parts[0];
        if (thousandsSeparator === ' ') {
            integerPart = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ').replace(",", ' ');
        }

        let decimalPart = parts.length > 1 ? decimalSeparator + `${parts[1]}` : '';
        if (thousandsSeparator === '.') {
            decimalPart = parts.length > 1 ? `.${parts[1]}` : '';
        }


        return `${integerPart}${decimalPart}`;
    }


    return (
        <Routes>

            <Route path="/pt/simulador" element={
                <PT_Calculo
                    currentLanguage={currentLanguage}
                    dataCarros={dataCarros}
                    temParametros={temParametros}
                    formatarNumero={formatarNumero}
                    nomeGeradoDaProposta={nomeGeradoDaProposta}
                    isDarkTheme={isDarkTheme}
                    setIsDarkTheme={setIsDarkTheme}
                />
            } />


            <Route path="/es/simulador" element={
                <ES_Calculo
                    currentLanguage={currentLanguage}
                    dataCarros={dataCarros}
                    temParametros={temParametros}
                    formatarNumero={formatarNumero}
                    nomeGeradoDaProposta={nomeGeradoDaProposta}
                    isDarkTheme={isDarkTheme}
                    setIsDarkTheme={setIsDarkTheme}
                />
            } />

            <Route path="*" element={
                <ErroPagina isDarkTheme={isDarkTheme} />
            } />

        </Routes>
    )
}
