import React from "react";
import { Checkbox, Collapse, Tooltip } from 'antd';
import { AiOutlineInfoCircle } from "react-icons/ai";

const { Panel } = Collapse;

export default function CalculoChaveMao({
    validarViaturaEletrico,
    temParametros,
    dataDeRegisto,
    novoOuUsado,
    tipoDeCombustivel,
    tipoDeVeiculoParaOutros,
    hibridoIsChecked,
    taxaDeServico,
    tipoDeVeiculoParaInsencoes,
    outrosServicos,
    cilindrada,
    emissoesGases,
    wltpIsChecked,
    particulas,
    isDarkTheme,
}) {

    return (
        <div className='card'>
            <div className='titulo-form'>
                <p className="font-semibold">Cálculo do ISV</p>
            </div>
            <form className='grid gap-5'>
                <div>
                    <div className="mx-auto grid grid-cols-1 sm:grid-cols-2 gap-2">
                        {validarViaturaEletrico ? (
                            <>

                                <div className='grid gap-1 content-start'>
                                    <p className='text-sm text-neutral-500 font-semibold'>Novo / Usado:</p>
                                    <span>{novoOuUsado || 'Sem informação'}</span>
                                </div>
                                <div className='grid gap-1 content-start'>
                                    <p className='text-sm text-neutral-500 font-semibold'>Tipo de Combustível:</p>
                                    <span>{tipoDeCombustivel || 'Sem informação'}</span>
                                </div>

                                {novoOuUsado === "Usado" && (

                                    <div className='grid gap-1 content-start'>
                                        <p className='text-sm text-neutral-500 font-semibold'>Data 1º Registo:</p>
                                        <span>{dataDeRegisto}</span>
                                    </div>

                                )}
                            </>
                        ) : (
                            <>

                                <div className='grid gap-1 content-start'>
                                    <p className='text-sm text-neutral-500 font-semibold'>Cilindrada:</p>
                                    <span>{cilindrada}</span>
                                </div>
                                <div className='grid gap-1 content-start'>
                                    <p className='text-sm text-neutral-500 font-semibold'>Novo / Usado:</p>
                                    <span>{novoOuUsado || 'Sem informação'}</span>
                                </div>


                                {tipoDeCombustivel !== "Eletrico" && (
                                    <>
                                        <div className='grid gap-1 content-start'>
                                            <p className='text-sm text-neutral-500 font-semibold'>Tipo de Combustível:</p>
                                            <span>{tipoDeCombustivel || 'Sem informação'}</span>

                                        </div>
                                        <div className='grid gap-1 content-start'>
                                            <p className='text-sm text-neutral-500 font-semibold'>Emissões Gases C02:</p>
                                            <span>{emissoesGases}</span>
                                        </div>
                                    </>
                                )}

                                {hibridoIsChecked || wltpIsChecked && (
                                    <>
                                        {hibridoIsChecked && (
                                            <div className='grid gap-1 content-start'>
                                                <p className='text-sm text-neutral-500 font-semibold'>Híbridos Plug-In:</p>
                                                <span>Sim</span>
                                            </div>
                                        )}
                                        <div className='grid gap-1 content-start'>
                                            <p className='text-sm text-neutral-500 font-semibold'>Norma:</p>
                                            {wltpIsChecked ? (
                                                <span>WLTP</span>
                                            ) : (
                                                <span>NDEC</span>
                                            )}
                                        </div>

                                    </>
                                )}
                                {novoOuUsado === "Usado" && (
                                    <div className='grid gap-1 content-start'>
                                        <p className='text-sm text-neutral-500 font-semibold'>Data 1º Registo:</p>
                                        {dataDeRegisto}
                                    </div>
                                )}
                                {tipoDeCombustivel === 'Gasoleo' && (
                                    <div className='grid gap-1 content-start'>
                                        <p className='text-sm text-neutral-500 font-semibold'>Partículas:</p>
                                        {particulas === 1 ? (
                                            <span>&gt;=0,001 g/km</span>
                                        ) : (
                                            <span>&lt;0,001 g/km</span>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </form>
        </div>
    )
}
