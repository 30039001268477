import { Image, Tooltip } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BiCopy, BiCheck } from 'react-icons/bi';
import { PopoverFuncionario } from '../../pt/user-profile/popover-func';
import { useTranslation } from 'react-i18next';


export default function FuncContactDetails({ isDarkTheme, nomePerfilDataFuncionario, fotoPerfilDataFuncionario, whatsappDataFuncionario, emailDataInternoFuncionario }) {

    const { t } = useTranslation();

    return (
        <div className={`${isDarkTheme ? "text-black" : "text-white"} card flex items-center gap-4 flex-col justify-center text-lg rounded-md p-2.5`}>
            <Image
                style={{ borderRadius: '50%' }}
                width={100}
                src={fotoPerfilDataFuncionario}
            />
            <div className='grid items-center justify-center justify-items-center gap-1'>
                <span className='font-semibold'>{t('contact_details.titulo_principal')}</span>
                <span className='text-center text-sm pl-10 pr-10'>{t('contact_details.phrase')}</span>
            </div>
            <PopoverFuncionario nome={t('contact_details.popover')} whats={whatsappDataFuncionario} email={emailDataInternoFuncionario} />
        </div >
    );
}
