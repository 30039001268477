import React, { useState, useEffect } from 'react';
import { Button, List, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules';
import PartilhaRedesProposta from './partilha/partilha-redes-proposta';
import { FaLink } from 'react-icons/fa6';
import { AiOutlineInfoCircle } from 'react-icons/ai';


export default function TabelaViaturaCliente({ currentLanguage, isDarkTheme, isMobile, dataCarros, isParticular, formatarNumero, allDataLinkPartilhado, nomeGeradoDaProposta }) {

    const { t } = useTranslation();

    // console.log(dataCarros)

    const codigoPostal = dataCarros.codigo_postal_number_code

    return (
        <>
            <div className={`${isDarkTheme ? "text-black" : "text-white"} flex flex-col gap-6 font-semibold text-lg`}>
                <div className='grid grid-cols-1 md:grid-cols-1 gap-8 rounded-md'>
                    <div>
                        <Swiper
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            // navigation={true}
                            modules={[Autoplay]}
                        >
                            {dataCarros.data.data.imagens && dataCarros.data.data.imagens.length > 0 && dataCarros.data.data.imagens.map((imagem, index) => (
                                <SwiperSlide key={index}>
                                    <img
                                        style={{ borderRadius: '5px', objectFit: 'cover', width: '100%' }}
                                        src={imagem}
                                        alt={`Image ${index + 1}`}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="swiper-scrollbar swiper-scrollbar-horizontal"></div>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <p className={`${isDarkTheme ? "text-black" : "text-white"} font-medium text-lg md:text-2xl md:pb-4`} style={{ overflowWrap: 'break-word' }}>
                            <b>{dataCarros.data.data.titulo}</b>
                        </p>

                        <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                            {/* <p className='text-sm text-neutral-500'>{t('tabela_viatura.preco')}: <br /><span className='text-xs md:text-lg text-white font-medium'>{isParticular ? formatarNumero(dataCarros.data.data.preco_final_carro) : formatarNumero(dataCarros.data.data.preco_final_carro_sem_iva)}€</span></p> */}
                            <p className='text-sm text-neutral-500'>{t('tabela_viatura.quilometros')}: <br /><span className={`${isDarkTheme ? "text-black" : "text-white"} text-xs md:text-lg font-medium`}>{dataCarros.data.data.quantidade_de_km}</span></p>
                            <p className='text-sm text-neutral-500'>{t('tabela_viatura.data_registo')}: <br /><span className={`${isDarkTheme ? "text-black" : "text-white"} text-xs md:text-lg font-medium`}>{dataCarros.data.data.data_registo || 'Sem informação'}</span></p>
                            {dataCarros.data.data.cilindrada ? (
                                <p className='text-sm text-neutral-500'>
                                    {t('tabela_viatura.cilindrada')}: <br />
                                    <span className={`${isDarkTheme ? "text-black" : "text-white"} text-xs md:text-lg font-medium`}>
                                        {dataCarros.data.data.cilindrada} ccm
                                    </span>
                                </p>
                            ) : null}
                            <p className='text-sm text-neutral-500'>{t('tabela_viatura.potencia')}: <br /><span className={`${isDarkTheme ? "text-black" : "text-white"} text-xs md:text-lg font-medium`}>
                                {dataCarros.data.data.potencia?.toString().includes('Hp') ? dataCarros.data.data.potencia?.toString().replace('Hp', 'CV') : dataCarros.data.data?.potencia + ' CV'}
                            </span></p>
                            <p className='text-sm text-neutral-500'>{t('tabela_viatura.combustivel')}: <br /><span className={`${isDarkTheme ? "text-black" : "text-white"} text-xs md:text-lg font-medium`}>{dataCarros.data.data.tipo_combustivel_traduzido || dataCarros.data.data.tipo_de_combustivel}</span></p>
                            {dataCarros.data.data.emissoes && dataCarros.data.data.combustivel_real !== "Electric" ? (
                                <p className='text-sm text-neutral-500'>
                                    {t('tabela_viatura.emissoes')}: <br />
                                    <span className={`${isDarkTheme ? "text-black" : "text-white"} text-xs md:text-lg font-medium`}>
                                        {dataCarros.data.data.emissoes}
                                    </span>
                                </p>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className={`${isDarkTheme ? "text-black" : "text-white"} mais-informacoes-box`}>
                    <h2 className='pt-2 pr-4 pb-2'>{t('tabela_viatura.mais_informacao')}</h2>
                    <List
                        style={{ padding: 0 }}
                        size="small"
                        dataSource={[
                            { title: `${t('tabela_viatura.titulo_da_proposta')}`, value: nomeGeradoDaProposta },
                            { title: `${t('tabela_viatura.n_proprietarios')}`, value: dataCarros.data.data.caracteristicas?.proprietarios_anteriores },
                            { title: `${t('tabela_viatura.estado')}`, value: dataCarros.data.data.novo },
                            { title: `${t('tabela_viatura.n_portas')}`, value: dataCarros.data.data.caracteristicas?.portas },
                            { title: `${t('tabela_viatura.cor_exterior')}`, value: dataCarros.data.data.caracteristicas?.cor },
                            { title: `${t('tabela_viatura.cor_interior')}`, value: dataCarros.data.data.caracteristicas?.corInterior },
                            { title: `${t('tabela_viatura.teve_acidentes')}`, value: dataCarros.data.data?.acidentes },
                            { title: `${t('tabela_viatura.tipo_de_caixa')}`, value: dataCarros.data.data?.tipo_de_caixa },
                        ]}
                        renderItem={(item) => (
                            <List.Item>
                                <div className='grid grid-cols-2 gap-4 w-full'>
                                    <p className={`${isDarkTheme ? "text-black" : "text-white"} text-xs sm:text-sm`}>{item.title}:</p>
                                    <span className={`${isDarkTheme ? "text-black" : "text-white"} text-xs font-normal sm:text-sm`}>{item.value ? item.value : `${t('tabela_viatura.sem_informacao')}`}</span>
                                </div>
                            </List.Item>
                        )}
                    />

                    {!isMobile && (
                        <div className='grid grid-cols-2 gap-4 w-full border-t py-2'>
                            <p className={`${isDarkTheme ? "text-black" : "text-white"} text-xs sm:text-sm`}>{t('tabela_viatura.ver_anuncio')}:</p>
                            <a href={allDataLinkPartilhado?.url} target="_blank" className={`${isDarkTheme ? "text-black" : "text-white"}  text-xs font-normal sm:text-sm flex gap-2 items-center`}>Link <FaLink /></a>
                        </div>
                    )}
                </div>
                {isMobile ? (
                    <Button
                        type="primary"
                        htmlType="submit"
                        href={allDataLinkPartilhado?.url}
                        target="_blank"
                        className={`${isDarkTheme ? "bg-black text-white" : "bg-white text-black"} font-bold rounded`}
                    >
                        {t('tabela_viatura.ver_anuncio')}
                    </Button>
                ) : null}
                {codigoPostal !== "" && currentLanguage === "pt" && (
                    <div className='localizacao-da-viatura'>
                        <div className='grid gap-4'>
                            <div className='flex gap-2 items-center'>
                                <p className='text-lg font-semibold'>Estimativa de entrega da viatura</p>
                            </div>
                            <hr></hr>
                            <div className='grid gap-4'>
                                {codigoPostal == "4" || codigoPostal == "5" || codigoPostal == "6" || codigoPostal == "7" ? (
                                    <>
                                        <div className={`${isDarkTheme ? "bg-white text-black" : "bg-black text-white"} flex flex-col gap-2 text-md border-card`}>
                                            {isMobile ? (
                                                <div className='flex gap-2 w-full justify-between items-center'>
                                                    <Tooltip title="Este prazo é indicativo e corresponde à duração prevista do processo, desde a aceitação da proposta até à entrega do carro. É calculado com base na média dos processos já concluídos.">
                                                        <AiOutlineInfoCircle className='icon-question' />
                                                    </Tooltip>
                                                    <div className='grid gap-1 items-center'>
                                                        <p className='text-xs sm:text-sm'>Tempo estimado até à entrega ao cliente</p>
                                                        <span className='font-bold text-xs sm:text-sm text-right'> 4 semanas</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='flex w-full justify-between items-center'>
                                                    <div className='flex gap-2 items-center'>
                                                        <Tooltip title="Este prazo é indicativo e corresponde à duração prevista do processo, desde a aceitação da proposta até à entrega do carro. É calculado com base na média dos processos já concluídos.">
                                                            <AiOutlineInfoCircle className='icon-question' />
                                                        </Tooltip>
                                                        <p className='text-xs sm:text-sm'>Tempo estimado até à entrega ao cliente</p>
                                                    </div>
                                                    <span className='font-bold text-xs sm:text-sm text-right'> 4 semanas</span>
                                                </div>
                                            )}
                                            <hr></hr>
                                            <div className='flex w-full justify-between items-center'>
                                                <div className='flex gap-2 items-center'>
                                                    <Tooltip title="Este prazo é indicativo e corresponde ao periodo entre a aceitação da proposta e a recolha da viatura no stand, por parte da transportadora.É calculado com base na média dos processos já concluídos.">
                                                        <AiOutlineInfoCircle className='icon-question' />
                                                    </Tooltip>
                                                    <p className='text-xs sm:text-sm text-neutral-500'>Tempo de recolha</p>
                                                </div>
                                                <span className='font-bold text-xs sm:text-sm text-neutral-500 text-right'> 2 semanas</span>
                                            </div>
                                            <div className='flex w-full justify-between items-center'>
                                                <div className='flex gap-2 items-center'>
                                                    <Tooltip title="Este prazo é indicativo e corresponde ao periodo entre a chegada da viatura a Portugal e a entrega ao cliente.É calculado com base na média dos processos já concluídos.">
                                                        <AiOutlineInfoCircle className='icon-question' />
                                                    </Tooltip>
                                                    <p className='text-xs sm:text-sm text-neutral-500'>Tempo de entrega</p>
                                                </div>
                                                <span className='font-bold text-xs sm:text-sm text-neutral-500 text-right'> 2 semanas</span>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className={`${isDarkTheme ? "bg-white text-black" : "bg-black text-white"} flex flex-col gap-2 text-md border-card`}>
                                            {isMobile ? (
                                                <div className='flex gap-2 w-full justify-between items-center'>
                                                    <Tooltip title="Este prazo é indicativo e corresponde à duração prevista do processo, desde a aceitação da proposta até à entrega do carro. É calculado com base na média dos processos já concluídos.">
                                                        <AiOutlineInfoCircle className='icon-question' />
                                                    </Tooltip>
                                                    <div className='flex gap-1 items-center'>
                                                        <p className='w-2/3  text-xs sm:text-sm'>Tempo estimado até à entrega ao cliente</p>
                                                        <span className='w-1/3 font-bold text-xs sm:text-sm text-right'> 6 semanas</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='flex w-full justify-between items-center'>
                                                    <div className='flex gap-2 items-center'>
                                                        <Tooltip title="Este prazo é indicativo e corresponde à duração prevista do processo, desde a aceitação da proposta até à entrega do carro. É calculado com base na média dos processos já concluídos.">
                                                            <AiOutlineInfoCircle className='icon-question' />
                                                        </Tooltip>
                                                        <p className='text-xs sm:text-sm'>Tempo estimado até à entrega ao cliente</p>
                                                    </div>
                                                    <span className='font-bold text-xs sm:text-sm text-right'> 6 semanas</span>
                                                </div>
                                            )}

                                            <hr></hr>
                                            <div className='flex gap-2 w-full justify-between items-center'>
                                                <Tooltip title="Este prazo é indicativo e corresponde ao periodo entre a aceitação da proposta e a recolha da viatura no stand, por parte da transportadora.É calculado com base na média dos processos já concluídos.">
                                                    <AiOutlineInfoCircle className='icon-question' />
                                                </Tooltip>
                                                <div className='flex gap-1 items-center w-full'>
                                                    <p className='w-2/3 text-xs sm:text-sm text-neutral-500'>Tempo de recolha</p>
                                                    <span className='w-1/3 font-bold text-xs sm:text-sm text-neutral-500 text-right'> 4 semanas</span>
                                                </div>
                                            </div>
                                            <div className='flex gap-2 w-full justify-between items-center'>
                                                <Tooltip title="Este prazo é indicativo e corresponde ao periodo entre a chegada da viatura a Portugal e a entrega ao cliente.É calculado com base na média dos processos já concluídos.">
                                                    <AiOutlineInfoCircle className='icon-question' />
                                                </Tooltip>
                                                <div className='flex gap-1 items-center w-full'>
                                                    <p className='w-2/3 text-xs sm:text-sm text-neutral-500'>Tempo de entrega</p>
                                                    <span className='w-1/3 font-bold text-xs sm:text-sm text-neutral-500 text-right'> 2 semanas</span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );

};