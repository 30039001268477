import React, { useState } from "react";
import { Modal, Tabs, Tooltip } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useTranslation } from 'react-i18next';



export default function ES_ResultadodaSimulacao({
    formatarNumero,
    valorFinalISV,
    valorFinalIUC,
    iucCavalosFiscais,
    iucCidade,
    iucMunicipio,
    iucPercentagemDescontoEletrico,
    isvComunidad,
    isvValoracionInicial,
    isvPorcentajeaAplicar,
    isvMinoracion,
    isvBaseImponible,
    isvReduccionBaseImponible,
    isvBaseImponibleFinal,
    isvCuotaTributaria,
    isvTotalaPagar,
    taxaDeServico,
    valorChaveNaMaoEmpresa,
    valorChaveNaMaoParticular,
    precoFinalDoCarroSemIva,
    activeTabKeyResultadoSimulacao,
    setActiveTabKeyResultadoSimulacao,
    precoFinalCarro,
    tabResultadoSimulacao }) {


    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenISV, setIsModalOpenISV] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModalISV = () => {
        setIsModalOpenISV(true);
    };

    const handleOkISV = () => {
        setIsModalOpenISV(false);
    };

    const handleCancelISV = () => {
        setIsModalOpenISV(false);
    };

    const handleTabChange = (key) => {
        setActiveTabKeyResultadoSimulacao(key);
        // if (activeTabKeyResultadoSimulacao === '2') {
        //     console.log('particular')
        // } else {
        //     console.log('empresa')
        // }
    };

    return (
        <div className='card'>
            <div className='titulo-form'>
                <p className="font-semibold">{t('resultadodasimulacao.titulo_principal')}</p>
            </div>
            <Tabs className='calculo-chave-na-mao' activeKey={activeTabKeyResultadoSimulacao} onChange={handleTabChange} tabBarGutter={16} centered>
                <TabPane tab={<span className='text-black'>{t('main.particular')}</span>} key="1">
                    <div>
                        <p className='text-black text-base flex justify-between'>{t('resultadodasimulacao.preco_do_carro')} <span
                            className='font-semibold text-lg'>{formatarNumero(precoFinalCarro)}€</span>
                        </p>
                        {/* <p className='text-black text-base flex justify-between'>
                                {t('main.isv')}:
                                <span className='font-semibold text-lg flex items-center gap-2'>
                                    {valorFinalISV ? `${formatarNumero(valorFinalISV)}€` : "0€"}
                                    <AiOutlineInfoCircle className='icon-question' onClick={showModalISV} />
                                </span>
                            </p> */}
                        <span className="text-black text-base flex justify-between">
                            <span class="flex items-center gap-1">
                                {t('main.isv')}
                                <AiOutlineInfoCircle className='icon-question' onClick={showModalISV} />
                            </span>
                            <span className='font-semibold text-lg flex items-center gap-2'>
                                {valorFinalISV ? `${formatarNumero(valorFinalISV)}€` : "0€"}
                            </span>
                        </span>
                        <Modal
                            title={<span className="flex items-center gap-2"><AiOutlineInfoCircle /> {t('resultadodasimulacao.explicacao_do_calculo')}</span>}
                            open={isModalOpenISV}
                            onOk={handleOkISV}
                            onCancel={handleCancelISV}
                            okText="Fechar"
                            footer={null}
                        >
                            <p className="text-black text-base flex justify-between">Comunidad: <span className="font-bold text-lg">{isvComunidad ? `${isvComunidad}` : `${t('tabela_viatura.sem_informacao')}`}</span></p>
                            <p className="text-black text-base flex justify-between">Valoración inicial: <span className="font-bold text-lg">{isvValoracionInicial ? `${isvValoracionInicial}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Porcentaje a aplicar: <span className="font-bold text-lg">{isvPorcentajeaAplicar ? `${isvPorcentajeaAplicar}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Minoración: <span className="font-bold text-lg">{isvMinoracion ? `${isvMinoracion}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Base imponible: <span className="font-bold text-lg">{isvBaseImponible ? `${isvBaseImponible}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Reducción base imponible: <span className="font-bold text-lg">{isvReduccionBaseImponible ? `${isvReduccionBaseImponible}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Base imponible final: <span className="font-bold text-lg">{isvBaseImponibleFinal ? `${isvBaseImponibleFinal}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Cuota tributaria: <span className="font-bold text-lg">{isvCuotaTributaria ? `${isvCuotaTributaria}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Total a pagar: <span className="font-bold text-lg">{isvTotalaPagar ? `${formatarNumero(isvTotalaPagar)}€` : "0€"}</span></p>
                        </Modal>
                        <p className='text-black text-base flex justify-between'>
                            <span className="flex items-center gap-2">
                                {t('resultadodasimulacao.taxa_de_servico')}
                                <Tooltip title="La tasa de servicio incluye el coste del transporte, el seguro, la legalización y todos los demás costes del proceso de importación.">
                                    <AiOutlineInfoCircle className='icon-question' />
                                </Tooltip>
                            </span>
                            <span className='font-semibold text-lg flex items-center gap-2'>{taxaDeServico ? `${formatarNumero(taxaDeServico)}€` : "0€"} </span>
                        </p>
                    </div>
                    <div>
                        <p className='center text-black text-base font-semibold flex justify-between'>  {t('resultadodasimulacao.preco_chave_nao_mao')} <span
                            className='text-lg sm:text-2xl font-extrabold'>{formatarNumero(valorChaveNaMaoParticular)}€</span>
                        </p>
                    </div>
                    <div className="border-t-4 pt-2 mt-2">
                        {/* <p className='text-black text-base flex justify-between'>
                                {t('main.iuc')}:
                                <span className='font-semibold text-lg flex items-center gap-2'>
                                    {valorFinalIUC ? `${formatarNumero(valorFinalIUC)}€` : "0€"}
                                    <AiOutlineInfoCircle className='icon-question' onClick={showModal} />
                                </span>
                            </p> */}
                        <span className="text-black text-base flex justify-between">
                            <span class="flex items-center gap-1">
                                {t('main.iuc')}
                                <AiOutlineInfoCircle className='icon-question' onClick={showModal} />
                            </span>
                            <span className='font-semibold text-lg flex items-center gap-2'>
                                {valorFinalIUC ? `${formatarNumero(valorFinalIUC)}€` : "0€"}
                            </span>
                        </span>
                        <Modal
                            title={<span className="flex items-center gap-2"><AiOutlineInfoCircle /> {t('resultadodasimulacao.explicacao_do_calculo')}</span>}
                            open={isModalOpen}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            okText="Fechar"
                            footer={null}
                        >
                            <p className="text-black text-base flex justify-between">Cavalos fiscais:: <span className="font-bold text-lg">{iucCavalosFiscais ? `${iucCavalosFiscais}` : `${t('tabela_viatura.sem_informacao')}`}</span></p>
                            <p className="text-black text-base flex justify-between">Cidade: <span className="font-bold text-lg">{iucCidade ? `${iucCidade}` : `${t('tabela_viatura.sem_informacao')}`}</span></p>
                            <p className="text-black text-base flex justify-between">Município: <span className="font-bold text-lg">{iucMunicipio ? `${iucMunicipio}` : `${t('tabela_viatura.sem_informacao')}`}</span></p>
                            <p className="text-black text-base flex justify-between">Percentagem desconto elétrico: <span className="font-bold text-lg">{iucPercentagemDescontoEletrico ? `${iucPercentagemDescontoEletrico}` : `${t('tabela_viatura.sem_informacao')}`}</span></p>
                            {/* <div className="border-t-4 pt-2 mt-2">
                                    <p className="text-black text-base flex justify-between">O IUC é pago anualmente pelo proprietário do veículo. A primeira guia de IUC tem de ser paga dentro dos 90 dias após emissão da DAV.</p>
                                </div> */}
                        </Modal>
                    </div>
                </TabPane>

                <TabPane tab={<span className='text-black'>Empresa</span>}
                    key="2">
                    <div>
                        <p className='text-black text-base flex justify-between'>{t('resultadodasimulacao.preco_do_carro')} <span
                            className='font-semibold text-lg'>{formatarNumero(precoFinalDoCarroSemIva)}€</span>
                        </p>
                        {/* <p className='text-black text-base flex justify-between'>
                                {t('main.isv')}:
                                <span className='font-semibold text-lg flex items-center gap-2'>
                                    {valorFinalISV ? `${formatarNumero(valorFinalISV)}€` : "0€"}
                                    <AiOutlineInfoCircle className='icon-question' onClick={showModalISV} />
                                </span>
                            </p> */}
                        <span className="text-black text-base flex justify-between">
                            <span class="flex items-center gap-1">
                                {t('main.isv')}
                                <AiOutlineInfoCircle className='icon-question' onClick={showModalISV} />
                            </span>
                            <span className='font-semibold text-lg flex items-center gap-2'>
                                {valorFinalISV ? `${formatarNumero(valorFinalISV)}€` : "0€"}
                            </span>
                        </span>
                        <Modal
                            title={<span className="flex items-center gap-2"><AiOutlineInfoCircle /> {t('resultadodasimulacao.explicacao_do_calculo')}</span>}
                            open={isModalOpenISV}
                            onOk={handleOkISV}
                            onCancel={handleCancelISV}
                            okText="Fechar"
                            footer={null}
                        >
                            <p className="text-black text-base flex justify-between">Comunidad: <span className="font-bold text-lg">{isvComunidad ? `${isvComunidad}` : `${t('tabela_viatura.sem_informacao')}`}</span></p>
                            <p className="text-black text-base flex justify-between">Valoración inicial: <span className="font-bold text-lg">{isvValoracionInicial ? `${isvValoracionInicial}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Porcentaje a aplicar: <span className="font-bold text-lg">{isvPorcentajeaAplicar ? `${isvPorcentajeaAplicar}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Minoración: <span className="font-bold text-lg">{isvMinoracion ? `${isvMinoracion}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Base imponible: <span className="font-bold text-lg">{isvBaseImponible ? `${isvBaseImponible}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Reducción base imponible: <span className="font-bold text-lg">{isvReduccionBaseImponible ? `${isvReduccionBaseImponible}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Base imponible final: <span className="font-bold text-lg">{isvBaseImponibleFinal ? `${isvBaseImponibleFinal}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Cuota tributaria: <span className="font-bold text-lg">{isvCuotaTributaria ? `${isvCuotaTributaria}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Total a pagar: <span className="font-bold text-lg">{isvTotalaPagar ? `${isvTotalaPagar}€` : "0€"}</span></p>
                        </Modal>
                        <p className='text-black text-base flex justify-between'>
                            <span className="flex items-center gap-2">
                                {t('resultadodasimulacao.taxa_de_servico')}
                                <Tooltip title="La tasa de servicio incluye el coste del transporte, el seguro, la legalización y todos los demás costes del proceso de importación.">
                                    <AiOutlineInfoCircle className='icon-question' />
                                </Tooltip>
                            </span>
                            <span className='font-semibold text-lg flex items-center gap-2'>{taxaDeServico ? `${formatarNumero(taxaDeServico)}€` : "0€"} </span>
                        </p>
                    </div>

                    <div>
                        <p className='center text-black text-base font-semibold flex justify-between'>{t('resultadodasimulacao.preco_chave_nao_mao')} <span
                            className='text-lg sm:text-2xl font-extrabold'>{formatarNumero(valorChaveNaMaoEmpresa)}€</span>
                        </p>
                    </div>
                    <div className="border-t-4 pt-2 mt-2">
                        {/* <p className='text-black text-base flex justify-between'>
                            {t('main.iuc')}:
                            <span className='font-semibold text-lg flex items-center gap-2'>
                                {valorFinalIUC ? `${formatarNumero(valorFinalIUC)}€` : "0€"}
                                <AiOutlineInfoCircle className='icon-question' onClick={showModal} />
                            </span>
                        </p> */}
                        <span className="text-black text-base flex justify-between">
                            <span class="flex items-center gap-1">
                                {t('main.iuc')}
                                <AiOutlineInfoCircle className='icon-question' onClick={showModal} />
                            </span>
                            <span className='font-semibold text-lg flex items-center gap-2'>
                                {valorFinalIUC ? `${formatarNumero(valorFinalIUC)}€` : "0€"}
                            </span>
                        </span>
                        <Modal
                            title={<span className="flex items-center gap-2"><AiOutlineInfoCircle /> {t('resultadodasimulacao.explicacao_do_calculo')}</span>}
                            open={isModalOpen}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            okText="Fechar"
                            footer={null}
                        >
                            <p className="text-black text-base flex justify-between">Comunidad: <span className="font-bold text-lg">{isvComunidad ? `${isvComunidad}` : `${t('tabela_viatura.sem_informacao')}`}</span></p>
                            <p className="text-black text-base flex justify-between">Valoración inicial: <span className="font-bold text-lg">{isvValoracionInicial ? `${isvValoracionInicial}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Porcentaje a aplicar: <span className="font-bold text-lg">{isvPorcentajeaAplicar ? `${isvPorcentajeaAplicar}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Minoración: <span className="font-bold text-lg">{isvMinoracion ? `${isvMinoracion}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Base imponible: <span className="font-bold text-lg">{isvBaseImponible ? `${isvBaseImponible}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Reducción base imponible: <span className="font-bold text-lg">{isvReduccionBaseImponible ? `${isvReduccionBaseImponible}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Base imponible final: <span className="font-bold text-lg">{isvBaseImponibleFinal ? `${isvBaseImponibleFinal}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Cuota tributaria: <span className="font-bold text-lg">{isvCuotaTributaria ? `${isvCuotaTributaria}€` : "0€"}</span></p>
                            <p className="text-black text-base flex justify-between">Total a pagar: <span className="font-bold text-lg">{isvTotalaPagar ? `${isvTotalaPagar}€` : "0€"}</span></p>
                        </Modal>
                    </div>
                </TabPane>

            </Tabs>
        </div>

    )
}
