import React, { useEffect, useState } from 'react';
import { AppRoutes } from './Routes';
import { useLocation } from "react-router-dom";
import './styles.css';
import './styles/index.css';
import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import axios from "axios";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import { FaMobileAlt } from 'react-icons/fa';
import { MdOutlineMail } from "react-icons/md";
import ptJSON from './locale/pt.json';
import esJSON from './locale/es.json';
import queryString from 'query-string';
import logo from './assets/logo-branco 2.svg';
import { useTranslation } from 'react-i18next';


const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


const dataNotFound = {
    website: 'https://importrust.com/',
    telefone: '351934341495',
    email: 'info@importrust.com'
};

export default function App() {





    const [isDarkTheme, setIsDarkTheme] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
    const [allLanguage, setAllLanguage] = useState(null);



    useEffect(() => {

        const parsed = queryString.parse(location.search);
        const nomeProposta = parsed.nome_proposta;
        const idProposta = parsed.id_proposta;
        const propuestaNombre = parsed.propuesta_nombre;
        const idPropuesta = parsed.id_propuesta;

        if (nomeProposta || idProposta || propuestaNombre || idPropuesta) {
            if (nomeProposta) {
                setCurrentLanguage('pt');
                setNomeGeradoDaProposta(nomeProposta);
                setValidarTipoDeParametro(true);

                const ogImage = '/Framept.png';
                let metaTag = document.querySelector('meta[property="og:image"]');
                if (metaTag) {
                    metaTag.setAttribute('content', ogImage);
                } else {
                    metaTag = document.createElement('meta');
                    metaTag.setAttribute('property', 'og:image');
                    metaTag.setAttribute('content', ogImage);
                    document.head.appendChild(metaTag);
                }

                // console.log(currentLanguage)
                // console.log(ogImage)

            } else if (idProposta) {
                setCurrentLanguage('pt');
                setNomeGeradoDaProposta(idProposta);
                setValidarTipoDeParametro(false);

                const ogImage = '/Framept.png';
                let metaTag = document.querySelector('meta[property="og:image"]');
                if (metaTag) {
                    metaTag.setAttribute('content', ogImage);
                } else {
                    metaTag = document.createElement('meta');
                    metaTag.setAttribute('property', 'og:image');
                    metaTag.setAttribute('content', ogImage);
                    document.head.appendChild(metaTag);
                }

                // console.log(currentLanguage)
                // console.log(ogImage)

            } else if (propuestaNombre) {
                setCurrentLanguage('es');
                setNomeGeradoDaProposta(propuestaNombre);
                setValidarTipoDeParametro(true);

                const ogImage = '/Framees.png';
                let metaTag = document.querySelector('meta[property="og:image"]');
                if (metaTag) {
                    metaTag.setAttribute('content', ogImage);
                } else {
                    metaTag = document.createElement('meta');
                    metaTag.setAttribute('property', 'og:image');
                    metaTag.setAttribute('content', ogImage);
                    document.head.appendChild(metaTag);
                }

                // console.log(currentLanguage)
                // console.log(ogImage)
            } else if (idPropuesta) {
                setCurrentLanguage('es');
                setNomeGeradoDaProposta(idPropuesta);
                setValidarTipoDeParametro(false);

                const ogImage = '/Framees.png';
                let metaTag = document.querySelector('meta[property="og:image"]');
                if (metaTag) {
                    metaTag.setAttribute('content', ogImage);
                } else {
                    metaTag = document.createElement('meta');
                    metaTag.setAttribute('property', 'og:image');
                    metaTag.setAttribute('content', ogImage);
                    document.head.appendChild(metaTag);
                }

                // console.log(currentLanguage)
                // console.log(ogImage)
            }
        } else {
            setLoading(false);
            setErroMensagem('');
            setErro(true);
        }

        i18n.use(initReactI18next).init({
            resources: {
                pt: { translation: { ...ptJSON } },
                es: { translation: { ...esJSON } }
            },
            lng: currentLanguage, // Set the language based on the currentLanguage state
            fallbackLng: "pt",
            interpolation: {
                escapeValue: false
            }
        });



        // // Dynamic og:image meta tag handling
        // const ogImage = currentLanguage === 'es' ? '/Framees.png' : '/Framept.png';
        // console.log(currentLanguage)
        // console.log(ogImage)

        // let metaTag = document.querySelector('meta[property="og:image"]');

        // if (metaTag) {
        //     metaTag.setAttribute('content', ogImage);
        // } else {
        //     metaTag = document.createElement('meta');
        //     metaTag.setAttribute('property', 'og:image');
        //     metaTag.setAttribute('content', ogImage);
        //     document.head.appendChild(metaTag);
        // }


    }, [currentLanguage]);


    // const { t } = useTranslation();

    const location = useLocation();


    //Responsive
    const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
    })


    const [temParametros, setTemParametros] = useState(true);
    const [validartipodeparametro, setValidarTipoDeParametro] = useState(true);
    const [loading, setLoading] = useState(true);
    const [erro, setErro] = useState(false);
    const [dataCarros, setDataCarros] = useState([]);
    const [nomeGeradoDaProposta, setNomeGeradoDaProposta] = useState('');
    const [erroMensagem, setErroMensagem] = useState('');
    const [contactos, setContactos] = useState([]);


    useEffect(() => {
        if (nomeGeradoDaProposta) {
            let data = {
                lang: currentLanguage
            };

            let pedido = (currentLanguage === 'pt') ? `${process.env.REACT_APP_GET_PROPOSTA_PT}` : `${process.env.REACT_APP_GET_PROPOSTA_ES}`;

            if (validartipodeparametro) {
                data.nome_proposta = nomeGeradoDaProposta;
            } else {
                data.id_proposta = nomeGeradoDaProposta;
            }

            axios.post(pedido, data)
                .then((response) => {
                    if (response.data && response.data.sucesso) {
                        setDataCarros(response); // Assuming response.data is an array or object
                        setLoading(false);
                        setErro(false);
                    } else {
                        setContactos(response.data && response.data.user ? response.data.user : dataNotFound);
                        setLoading(false);
                        setErroMensagem(response.data && response.data.mensagem ? response.data.mensagem : 'Erro ao carregar dados.');
                        setErro(true);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    setErro(true);
                    setErroMensagem('Erro ao carregar dados.');
                });
        }
    }, [nomeGeradoDaProposta, currentLanguage, validartipodeparametro]);


    const handleShare = () => {
        const whatsappUrl = `https://api.whatsapp.com/send/?phone=+351934341495&text=Ol%C3%A1!%20Somos%20especialistas%20em%20Importa%C3%A7%C3%A3o%20Autom%C3%B3vel.%20Como%20podemos%20ajudar?`;
        window.open(whatsappUrl, '_blank');
    };


    const toggleTheme = () => {
        setIsDarkTheme(!isDarkTheme);
    };

















    return (
        <div className={`${isDarkTheme ? "light-theme" : "dark-theme"} max-w-screen-xl m-auto`}>
            {loading ? (
                <div className="p-4 flex flex-row gap-4 h-screen text-primary" >
                    <div className="main-content flex flex-col gap-5 justify-center">
                        <div className="overflow-auto h-screen pr-5 pl-5">
                            <div className="basis-1/2 flex flex-col items-center justify-center gap-4 h-full">
                                <Spin indicator={antIcon} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : erro ? (
                <div className="p-2 sm:p-4 flex flex-row gap-4 h-screen">
                    <div className="w-full card flex items-center gap-5 flex-col justify-center text-lg rounded-md p-2.5">
                        {contactos && (
                            <>
                                <div className="grid gap-7 justify-items-center items-center">
                                    <div>
                                        <img
                                            src={logo}
                                            alt="Logo"
                                            className="w-56 max-w-56 h-10 object-contain cursor-pointer"
                                            onClick={() => window.open(`${contactos.website}`, '_blank')}
                                        />
                                    </div>
                                    <div className="grid justify-items-center items-center gap-2">
                                        <p className={`${isDarkTheme ? "text-black" : "text-white"}`}>
                                            <a className="flex items-center gap-2" href={`tel:+${contactos.telefone ? (contactos.telefone) : (dataNotFound.telefone)}`}>
                                                <FaMobileAlt /> +{contactos.telefone ? (contactos.telefone) : (dataNotFound.telefone)}
                                            </a>
                                        </p>
                                        <p className={`${isDarkTheme ? "text-black" : "text-white"}`}>
                                            <a className="flex items-center gap-2" href={`mailto:${contactos.email ? (contactos.email) : (dataNotFound.email)}`}>
                                                <MdOutlineMail /> {contactos.email ? (contactos.email) : (dataNotFound.email)}
                                            </a>
                                        </p>
                                    </div>
                                    {erroMensagem && (
                                        <p className={`${isDarkTheme ? "text-black" : "text-white"} flex border rounded p-3 items-center justify-center frase-style`}>{erroMensagem}</p>
                                    )}
                                    <div className="flex md:flex-row gap-4">
                                        <Button type="primary" htmlType="button" onClick={handleShare} className={`${isDarkTheme ? "text-black" : "text-white"} btn-green bg-green-500 hover:bg-green-500 rounded flex items-center gap-2`}>
                                            <FaWhatsapp className="text-2xl" />
                                        </Button>
                                        <Button type="primary" htmlType="button" onClick={() => window.open('https://instagram.com/importrust', '_blank')} className={`${isDarkTheme ? "text-black" : "text-white"} bg-purple-500 hover:bg-purple-500 rounded flex items-center gap-2`}>
                                            <FaInstagram className="text-2xl" />
                                        </Button>
                                        <Button type="primary" htmlType="button" onClick={() => window.open('https://linkedin.com/company/importrust', '_blank')} className={`${isDarkTheme ? "text-black" : "text-white"} bg-blue-500 hover:bg-blue-500 rounded flex items-center gap-2`}>
                                            <FaLinkedin className="text-2xl" />
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <AppRoutes
                    currentLanguage={currentLanguage}
                    isDarkTheme={isDarkTheme}
                    setIsDarkTheme={setIsDarkTheme}
                    temParametros={temParametros}
                    dataCarros={dataCarros}
                    nomeGeradoDaProposta={nomeGeradoDaProposta}
                />
            )
            }
        </div >
    );
}


