import { Tooltip } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BiCopy, BiCheck } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';


export default function LinkPartilhaExpira({ isDarkTheme, infoInicialPartilhaProposta }) {

    const { t } = useTranslation();

    return (
        <div className='card flex items-center gap-5 flex-col justify-center text-lg rounded-md p-2.5'>
            <Tooltip title={t('data_expira.tooltip')}>
                <AiOutlineInfoCircle className={`${isDarkTheme ? "text-black" : "text-white"} icon-question  text-2xl`}/>
            </Tooltip>
            <p className={`${isDarkTheme ? "text-black" : "text-white"} text-sm frase-style`}>{t('data_expira.titulo_principal')} {infoInicialPartilhaProposta}</p>
        </div >
    );
}
