import { Button } from "antd";
import { BiError } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa6";
import { HiOutlineMail } from "react-icons/hi";

export function PopoverFuncionario(props) {

    // console.log(props.nome)
    // console.log(props.whats)
    // console.log(props.email)

    return (
        <div className="flex flex-row gap-2">
            <Button type="primary" htmlType="button" onClick={() => window.open(`${props.whats}`, '_blank')} className="btn-green bg-green-500 text-white rounded flex items-center gap-2">
                <FaWhatsapp className='text-2xl' /> Whatsapp
            </Button>

            <Button type="primary" htmlType="button" onClick={() => window.open(`mailto:${props.email}`, '_blank')} className="btn-blue bg-blue-500 text-white rounded flex items-center gap-2">
                <HiOutlineMail className='text-2xl' /> Email
            </Button>
        </div>
    )
}
