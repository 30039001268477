import React, { useState } from 'react';
import { FiExternalLink, FiCopy, FiCheck } from 'react-icons/fi';
import { FaWhatsapp, FaTelegramPlane, FaFacebook, FaInstagram, FaEnvelope } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { HiOutlineMail } from 'react-icons/hi';

export default function PartilhaRedesProposta({ isDarkTheme, nomeGeradoDaProposta, tituloDoMobileDaViatura }) {
    const { t } = useTranslation();
    const [isCopied, setIsCopied] = useState(false);

    const currentDomain = window.location.origin;
    const shareUrl = `${currentDomain}/pt/simulador/?nome_proposta=${nomeGeradoDaProposta}`;
    const shareMessage = `Veja esta proposta de importação de um ${tituloDoMobileDaViatura} que recebi da Importrust.`;

    const handleCopy = () => {
        navigator.clipboard.writeText(shareUrl);
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    };

    return (
        <div className='flex gap-4 items-center justify-between'>
            <h2 className={`${isDarkTheme ? "text-black" : "text-white"} text-xs sm:text-sm font-semibold`}>{t('tabela_viatura.partilhar')}</h2>
            <div className='flex gap-1 flex-col'>
                <div className="flex flex-row gap-2">
                    <Button
                        type="primary"
                        onClick={() => window.open(`https://wa.me/?text=${encodeURIComponent(shareMessage)}%20${encodeURIComponent(shareUrl)}`, '_blank')}
                        className="flex gap-2 h-full bg-green-500 items-center font-bold rounded p-2"
                    >
                        <FaWhatsapp className="text-lg" />
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => window.open(`mailto:?subject=${encodeURIComponent('Proposta de Importação')}&body=${encodeURIComponent(shareMessage)}%20${encodeURIComponent(shareUrl)}`, '_blank')}
                        className="flex gap-2 h-full bg-blue-500 items-center font-bold rounded p-2"
                    >
                        <HiOutlineMail className="text-lg" />
                    </Button>
                    {/* <Button
                        type="primary"
                        onClick={() => window.open(`https://t.me/share/url?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareMessage)}`, '_blank')}
                        className="flex gap-2 h-full bg-blue-400 items-center font-bold rounded p-2"
                    >
                        <FaTelegramPlane className="text-lg" />
                    </Button> */}
                    {/* <Button
                        type="primary"
                        onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`, '_blank')}
                        className="flex gap-2 h-full bg-blue-600 items-center font-bold rounded p-2"
                    >
                        <FaFacebook className="text-lg" />
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => window.open(`https://www.instagram.com/?url=${encodeURIComponent(shareUrl)}`, '_blank')}
                        className="flex gap-2 h-full bg-pink-500 items-center font-bold rounded p-2"
                    >
                        <FaInstagram className="text-lg" />
                    </Button> */}
                    <Button
                        type="primary"
                        onClick={handleCopy}
                        className="flex gap-2 h-full bg-gray-600 items-center font-bold rounded p-2"
                    >
                        {isCopied ? <FiCheck className="text-lg" /> : <FiCopy className="text-lg" />}
                    </Button>
                </div>
            </div>
        </div>
    );
}
